import React, { useEffect, useRef } from 'react';
import { Paper, Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { transTypeGraphFilterOps, transTypeRentalGraphFilterOps } from '../'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
Highcharts.setOptions({
    lang: {
      numericSymbols: ["k", "M", "B", "T"],
      thousandsSep: ","
    }
});

const CashflowAcTypeGraph = ({cashflowAccTypeGraph, skeletonLoader, filter, getResponseBack, callExternalFunction, selectedTab, cashflowRentalAccTypeGraph}) => {
    let filterOptions = selectedTab === 0 ? Object.assign({}, transTypeGraphFilterOps) : Object.assign({}, transTypeRentalGraphFilterOps) ;
        const getYAxisConfig = () => {
        if (selectedTab === 0) {
            return {
                min: 0,
                title: {
                    text: 'Reserve Amount (US$)'
                }
            };
        } else if (selectedTab === 1) {
            return {
                min: 0,
                title: {
                    text: 'Rental Amount (US$)'
                }
            };
        } else {
            return {
                min: 0,
                title: {
                    text: 'Amount (US$)'
                }
            };
        }
    };
    const getSeriesData = (selectedTab) => {
        if (selectedTab === 0) {
            return cashflowAccTypeGraph && cashflowAccTypeGraph.reserve_cashflow || [];
        } else if (selectedTab === 1) {
            return cashflowRentalAccTypeGraph && cashflowRentalAccTypeGraph.rental_cashflow || [];
        } else {
            return cashflowAccTypeGraph && cashflowAccTypeGraph.reserve_cashflow || [];
        }
    };
    const options = {
        chart: {
            zoomType: 'xy',
            panning: true,
            panKey: 'shift'
        },
        title: {
            text: selectedTab===1?'Based on Rental Rate Type':'Based on Reserve Rate Type',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize: '16px'
            }
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valuePrefix: 'US$',
        },
        subtitle: {
            text: selectedTab === 0 ? `${cashflowAccTypeGraph.title ? cashflowAccTypeGraph.title : ''}`:`${cashflowRentalAccTypeGraph.title ? cashflowRentalAccTypeGraph.title : ''}`,
            align: 'left',
        },
        yAxis: getYAxisConfig(), 
        xAxis: {
            categories: selectedTab === 0 ? cashflowAccTypeGraph && cashflowAccTypeGraph.cashflow_categories && cashflowAccTypeGraph.cashflow_categories.x_axis && cashflowAccTypeGraph.cashflow_categories.x_axis.categories : cashflowRentalAccTypeGraph && cashflowRentalAccTypeGraph.cashflow_categories && cashflowRentalAccTypeGraph.cashflow_categories.x_axis && cashflowRentalAccTypeGraph.cashflow_categories.x_axis.categories
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        callExternalFunction(event.point.series.name, event.point.category,selectedTab===1 ? 'basedOnRentalType' : 'basedOnReserveType');
                    }
                }
            }
        },
        series: getSeriesData(selectedTab), 
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                }
            },
            filename: 'Cashflow-Based on Reserve Rate Type Chart',
         }
    }

    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.chart.update(options);
            chartRef.current.chart.series.forEach((series) => {series.update({visible: true})});
        }
    }, [selectedTab, cashflowAccTypeGraph, cashflowRentalAccTypeGraph]); 

    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider />
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef} 
                />
            </div>
        </Paper>
    )
}

export default CashflowAcTypeGraph;
