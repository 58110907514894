export const addSubLease = {
  sublease_lessee: '',
  details: '',
  start_date: null,
  end_date: null,
  remarks: ''
}
export const subleaseHd = [
  { label: 'Name', sortOption: false },
  { label: 'Details', sortOption: false },
  { label: 'Start Date', sortOption: false },
  { label: 'End Date', sortOption: false },
  { label: 'Attachment(s)', sortOption: false },
  { label: 'Action', sortOption: false },
];
export const errorCode = {
  sublease_lessee:{
    0:'',
    1: 'Please enter Sublease Name'
  },
  sublease_lesseeObj: {
    required: true
  }
}
