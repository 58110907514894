import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { globalPutService } from '../../../globalServices'
import checkApiStatus from '../../../utils'
import { useSnackbar } from 'notistack'
import { PageLoader } from '../../../shared_elements'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { backendDateFormat, fieldDateFormat } from '../../../constants'
import moment from 'moment'

export default function StatusChangeDialog({ invStatus, setStatusChangeList, getResponseBack, invData }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [partialPaidAmount, setPartialPaidAmount] = useState(null);
    const [partialPaidDate, setPartialPaidDate] = useState(null);
    const [paidDate, setPaidDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statusChangeTriggered, setStatusChangeTriggered] = useState(false);
    useEffect(() => {
        if (selectedStatus?.value !== 5) {
            setPartialPaidAmount(null);
            setPaidDate(null);
            setPartialPaidDate(null)
        }
        if (selectedStatus?.value === 5 && !partialPaidAmount) {
            setPartialPaidAmount(null);
        }
    }, [selectedStatus]);
    const invoiceStatus = [{ label: 'Draft', value: 1 }, { label: 'Sent', value: 2 }, { label: 'Paid', value: 3 }, { label: 'Partially-Paid', value: 5 }]
    const onStatusSelect = (event, value) => {
        setSelectedStatus(value);
        setStatusChangeTriggered(true);
    };
    const onSaveChanges = () => {  
        if (selectedStatus.value === 5) {
            const partialDate = moment(partialPaidDate);
            if (!partialPaidAmount && !partialDate.isValid()&& partialPaidDate !==null) {
                enqueueSnackbar('Please Enter Partial Payment Amount and valid Partial Payment Date ', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if (!partialPaidAmount && partialDate.isAfter(moment(), 'day')) {
                enqueueSnackbar(`Please Enter Partial Payment Amount and Partial Payment Date can't be future.` , { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if (!partialPaidAmount && !partialPaidDate) {
                enqueueSnackbar('Please Enter Partial Payment Amount and Partial Payment Date.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if (!partialPaidAmount) {
                enqueueSnackbar('Please Enter Partial Payment Amount.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if (!partialPaidDate) {
                enqueueSnackbar('Please Enter Partial Payment Date.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if (!partialDate.isValid()) {
                enqueueSnackbar('Please Enter Valid Partial Payment Date.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
    
            if (partialDate.isAfter(moment(), 'day')) {
                enqueueSnackbar("Partial Payment Date can't be future.", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
        }
        if (selectedStatus.value === 3 ) {
            if(!paidDate){
                enqueueSnackbar('Please Enter Payment Date.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if(moment(paidDate).isAfter(moment(), 'day')){
                enqueueSnackbar(`Payment Date can't be future.`, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            if(!moment(paidDate).isValid()&& moment(paidDate)!==null){
                enqueueSnackbar(`Please Enter valid Payment Date.`, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
        }
        let payload={
        status: selectedStatus?.value,
        partial_paid_amount: Number(partialPaidAmount) || null,
        partial_paid_date: partialPaidDate,
        paid_date:paidDate
        }
        setLoading(true);
        globalPutService(`contracts/summary-invoice/${invData?.slug}/status-update/`,payload)
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    getResponseBack();
                    setStatusChangeList(false);
                    enqueueSnackbar(response.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    };
    const handlePartialPaidAmountChange = (e) => {
        setPartialPaidAmount(e.target.value);
    };

    const onDateChange = (date) => {
        setPartialPaidDate(moment(date).format(backendDateFormat));
    };
    const handlePaidDate = (date) => {
        setPaidDate(moment(date).format(backendDateFormat));
    };
    const filteredInvoiceStatus = invoiceStatus.filter(status => invStatus?.value === 1 ? [2, 3, 5].includes(status?.value) : invStatus?.value === 2 ? [3, 5].includes(status?.value) : invStatus?.value === 5 ? [3, 5].includes(status?.value) : true);
    const currencySymbol = invData?.currency === 'USD' ? '$' : '€';

    return (
        <>
            {isLoading ? <PageLoader /> : null}
            <Dialog
                onClose={() => setStatusChangeList(false)}
                open={true}
                className='lease-management-modal'
            >
                <DialogTitle> Change Invoice Status</DialogTitle>
                <DialogContent dividers='true' style={{ width: '400px' }}>
                    <Autocomplete
                        style={{ marginTop: '16px' }}
                        options={filteredInvoiceStatus}
                        getOptionLabel={option => option?.label}
                        // value={selectedStatus}
                        id="major_assembly"
                        disableClearable={true}
                        onChange={onStatusSelect}
                        renderInput={params => <TextField required {...params} label="Invoice Status" placeholder="Select Status" InputLabelProps={{ shrink: true }} variant="outlined" />}
                    />
                    {selectedStatus?.value === 5 && (
                        <>
                            <TextField
                                label="Partial Payment Amount"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={partialPaidAmount}
                                onChange={handlePartialPaidAmountChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: '16px' }}
                                inputProps={{ min: 0 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <p style={{ fontSize: '14px' }}>{currencySymbol}</p>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder='DD-MM-YYYY'
                                    style={{ marginTop: '16px' }}
                                    inputVariant="outlined"
                                    variant='outlined'
                                    margin="none"
                                    id="date"
                                    required
                                    label="Partial Payment Date"
                                    value={partialPaidDate|| null}
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={onDateChange} 
                                    helperText={''}
                                    error={''}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </>
                    )}
                    { selectedStatus?.value === 3 &&(
                        <>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder='DD-MM-YYYY'
                                    style={{ marginTop: '16px' }}
                                    inputVariant="outlined"
                                    variant='outlined'
                                    margin="none"
                                    id="date"
                                    required
                                    label="Payment Date"
                                    value={paidDate|| null}
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handlePaidDate} 
                                    helperText={''}
                                    error={''}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant="outlined" color="primary" onClick={() => { setStatusChangeList(false) }}>
                        Cancel
                    </Button>
                    <Button disabled={!statusChangeTriggered} size='small' variant="contained" color="primary" onClick={onSaveChanges}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
