
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip } from '@material-ui/core'
import React, { Fragment } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { backendDateFormat, fieldDateFormat } from '../../../../constants';
import { DropzoneArea } from 'material-ui-dropzone';
import { regexConstants } from '../../../../constants/regEx';
import { checkPermission } from '../../../../utils_v2';
import { gaTelesisInstance } from '../../../../shared_elements/components';

export default function AddEditAmendment({ leaseInfo, validationMsgs,handleDelete,previewDoc, setAttatchedDoc, updateErrorField, setModal, modal, addEditAmendment, lesseeList, lessorList, amendmentDetails, onChangeAmendmentDetails, setEditModal, setValidationMsgs, minDateVal }) {
    return (
        <Fragment>
            { checkPermission('contracts','lease_details','C') ?
                <Button style={{ marginBottom: '13px' }} variant="outlined" color="primary" onClick={() => { setModal(true); setEditModal({}); setValidationMsgs({}) }}><AddIcon fontSize='small' />Add Amendment</Button>:null
            }
            {
                modal ?
                    <Dialog style={{zIndex:"300"}} open='true' onClose={() => setModal(false)} maxWidth={'md'} className='mou-dialog'>
                        <DialogTitle> {amendmentDetails?.id ? ' Edit ' : 'Add '} Amendment Details </DialogTitle>
                        <DialogContent dividers style={{ width: "950px", }}>
                            <Grid container spacing='3'>
                                <Grid item sm='8'>
                                    <Grid container spacing='3'>
                                        <Grid item sm='6'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    minDate={minDateVal}
                                                    maxDate={moment(leaseInfo?.contractual_end_date)}
                                                    label="Lease Amendment Date"
                                                    value={amendmentDetails?.date ? amendmentDetails?.date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.date ? true : false}
                                                    helperText={validationMsgs.date ? validationMsgs.date : ''}
                                                    onFocus={() => updateErrorField('date')}
                                                    onChange={(data, value) => { onChangeAmendmentDetails('date', data ? moment(data).format(backendDateFormat) : null) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='6'>
                                            <TextField
                                                required
                                                label='Amendment Number'
                                                id="amendment_no"
                                                fullWidth
                                                placeholder='Please Enter Amendment Number'
                                                margin="normal"
                                                value={amendmentDetails.unique_identifier ? amendmentDetails.unique_identifier : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 25 }}
                                                error={validationMsgs.unique_identifier ? true : false}
                                                onFocus={() => updateErrorField('unique_identifier')}
                                                helperText={validationMsgs.unique_identifier ? validationMsgs.unique_identifier : ''}
                                                onChange={(e, data) => onChangeAmendmentDetails('unique_identifier', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                label='TSN'
                                                placeholder='Enter TSN'
                                                id="tsn"
                                                fullWidth
                                                margin="normal"
                                                value={amendmentDetails?.tsn ? amendmentDetails?.tsn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 25 }}
                                                error={validationMsgs.tsn ? true : false}
                                                helperText={validationMsgs.tsn ? validationMsgs.tsn : ''}
                                                onFocus={() => updateErrorField('tsn')}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onChangeAmendmentDetails('tsn', e.target.value ? e.target.value: null) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                label='CSN'
                                                placeholder='Enter CSN'
                                                id="csn"
                                                fullWidth
                                                margin="normal"
                                                value={amendmentDetails?.csn ? amendmentDetails?.csn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 25 }}
                                                error={validationMsgs.csn ? true : false}
                                                helperText={validationMsgs.csn ? validationMsgs.csn : ''}
                                                onFocus={() => updateErrorField('csn')}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onChangeAmendmentDetails('csn', e.target.value ? e.target.value : null) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='6'>
                                            <TextField
                                                required
                                                label='Amendment Description'
                                                id="asset"
                                                fullWidth
                                                placeholder='Please Enter Executed By'
                                                margin="normal"
                                                value={amendmentDetails.description ? amendmentDetails.description : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 250 }}
                                                error={validationMsgs.description ? true : false}
                                                onFocus={() => updateErrorField('description')}
                                                helperText={validationMsgs.description ? validationMsgs.description : ''}
                                                onChange={(e, data) => onChangeAmendmentDetails('description', e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm='4'>
                                    <DropzoneArea
                                        filesLimit={gaTelesisInstance ? (50 - (amendmentDetails?.attachments?.length || 0)) : 1}
                                        acceptedFiles={['.pdf']}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        maxFileSize={5000000000000}
                                        useChipsForPreview
                                        dropzoneText={<p>Drag & Drop Documents<br /> OR <br /> Click Here</p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['error', 'info']}
                                        onChange={(files) => { setAttatchedDoc(files); updateErrorField('amendmentDoc') }}
                                        // onDrop={(files) => addAttachment('attatchedDoc', files)}
                                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' }, style:{marginTop:'30px'} }}
                                    >
                                    </DropzoneArea>
                                    {gaTelesisInstance?
                                    <h6 style={{ marginTop: '5px', fontSize: '11px' }}>
                                        <b>Note:</b> Only pdf.(Maximum Upto 50 files.)
                                        <br />
                                        {amendmentDetails.id ?<>
                                        <b style={{font:'menu'}}>{amendmentDetails?.attachments?.length || 0}</b> files uploaded.
                                        <b style={{font:'menu'}}>{(50 - (amendmentDetails?.attachments?.length || 0))}</b> files remaining.
                                        </>: null}
                                    </h6>:null}                                 
                                    {
                                        amendmentDetails?.id && amendmentDetails?.attachments?.length ?
                                            amendmentDetails?.attachments.map(i =>
                                                <Tooltip title={`${i.name.substring(0, i.name.lastIndexOf('_'))}.pdf`} arrow placement='top' style={{ marginTop: '3px' }}>
                                                    <Chip
                                                        style={{ marginRight: '4px', marginTop: '7px' }}
                                                        variant="outlined"
                                                        size="small"
                                                        onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                                        label={i.name.substring(0, 14) + '...'}
                                                        clickable
                                                        color="primary"
                                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                                                    />
                                                </Tooltip>) : null
                                    }
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions dividers>
                            <Button style={{ marginRight: '10px' }} color='primary' variant='outlined' size='small' onClick={() => setModal(false)} >
                                Cancel
                            </Button>
                            <Button color='primary' variant='contained' size='small' onClick={() => addEditAmendment()} >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null}
        </Fragment>
    )
}

