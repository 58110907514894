import React, { Component } from 'react';
import { jetStreamInstance } from '../../../../lease_management/Leases';

export default class LeaseRentalHeader extends Component {
 constructor(props) {
  super(props);
 }
 render() {
  return (
   <thead>
    <tr>
     <th> Type</th>
     {jetStreamInstance?<th> Account Type</th>:null}
     <th> Currency</th>
     <th> Interval</th>
     <th> Amount</th>
     <th> Escalation Percentage</th>
     <th>Start Date</th>
     <th>End Date</th>
     <th></th>

    </tr>
   </thead>
  )
 }
}