import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { displayDateFormatShort } from '../../../../constants';
import QfdMntEventCRU from './QfdMntEventCRU'
import { titanInstance } from '../../../../shared_elements/components';
export default function QfdMntEventList({ item, previewDetail, reserveAccounts, getResponseBack, contractInfo, deleteQfdMntEvents }) {
  const [showMore, setShowMore] = useState({});
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.reserve_account.name}</TableCell>
      <TableCell>{item.event ? item.event : '--'}</TableCell>
      <TableCell style={{ maxWidth:!titanInstance ?'400px':'',width:titanInstance?'600px':'' }}>
        {item?.qualified_items && item?.qualified_items?.length > 200 ? (
          <div style={{ maxWidth:!titanInstance ?'400px':'',width:titanInstance?'600px':'' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.qualified_items.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.qualified_items.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.qualified_items.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </TableCell>

      <TableCell>
        {titanInstance ? <>
          {item.start_date ? moment(item.start_date).format(displayDateFormatShort) : ''} <br />  {item.start_date && item.end_date ? 'to' : null}  <br />  {item.end_date ? moment(item.end_date).format(displayDateFormatShort) : ''}
        </>
          : <>{item.start_date ? moment(item.start_date).format(displayDateFormatShort) : ''}  - {item.end_date ? moment(item.end_date).format(displayDateFormatShort) : ''}</>}
      </TableCell>
      <TableCell style={{textAlign:'right'}}>
        <QfdMntEventCRU contractInfo={contractInfo} id={item.id} m="V" reserveAccounts={reserveAccounts} getResponseBack={getResponseBack} />
        {checkPermission('contracts', 'qualified_maintenance_event', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{ cursor: 'pointer' }} onClick={() => deleteQfdMntEvents(item.id)} /> : null}
      </TableCell>
    </TableRow>
  )
}
