import React from 'react';
import {  Tooltip } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PieChartIcon from '@material-ui/icons/PieChart';
import { trackActivity } from '../../../utils/mixpanel';
const Switcher = ({mode, onChangeMode}) => {
    return(
        <span className='list-graph-sw'>
            <span className='list-view'>
                <Tooltip title="List View" arrow>
                    <ListAltIcon 
                        fontSize='small'
                        className="list-icon" 
                        onClick={() => {onChangeMode('list');
                        trackActivity('Lease Management',{
                            event_type: 'Page Visited',
                            page_title: 'Switcher(Fleet Leases List View)' })}}
                            color={mode === 'list' ? 'primary' : 'default'} 
                    />
                </Tooltip>
            </span>
            <span className='graph-view'>
                <Tooltip title="Graph View" arrow>
                    <PieChartIcon 
                        fontSize='small'
                        className="pie-graph-icon" 
                        color={mode === "graph" ? 'primary' : 'default'} onClick={() => {onChangeMode('graph');;
                        trackActivity('Lease Management',{
                        event_type: 'Page Visited',
                        page_title: 'Switcher(Fleet Leases Analytics View)' })}}
                    />
                </Tooltip>
            </span>
        </span>
    )
}
export default Switcher;