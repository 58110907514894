import * as actions from '../actions';
import { globalGetService, globalUnlimitedDownloadService } from '../../../globalServices'
import { trackActivity } from '../../../utils/mixpanel'
import { toastFlashMessage } from '../../../utils'
export const getFilterOptionsAc = (obj) =>{
  return(dispatch) =>{
    dispatch({
      type: actions.GLOBAL_SEARCH_LOADER,
      payload: true
    })
    globalGetService('/get_filter_details/', obj)
    .then(response => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
      if(response.data.statusCode === 200){
        dispatch({
          type: actions.ASSET_FILTERS,
          payload: response.data.data
        })
      }
    })
    .catch(error => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
    })
  }
}

export const getSearchResultAc = (params, query, application, level, pageNumber, filters, noFilter) => {
  return(dispatch) => {
    let data = {};
    if(params.aircraft_slug != undefined && params.aircraft_slug != null && params.aircraft_slug != '' && level && level.toLowerCase() != 'fleet'){
      data = {q: query, page: pageNumber, application: application != undefined ? application && application.toLowerCase() : '', asset_type:params.type, level: params.aircraft_slug, ...filters}
    }else {
      data = {q: query, page: pageNumber, application: '', asset_type: '', level: 'fleet', ...filters}
    }
    dispatch({
      type: actions.GLOBAL_SEARCH_RESULT,
      payload: {
        data:{
          "results": [],
          "pagination": {
              "total": 0,
              "current_page": "0",
              "number_of_pages": 0
          }
        }
      }
    })
    dispatch({
      type: actions.GLOBAL_SEARCH_LOADER,
      payload: true
    })
    dispatch({
      type: actions.PREVIEW_RECORD,
      payload: {}
    })
    globalGetService('search/',data)
    .then((response) => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.GLOBAL_SEARCH_RESULT,
            payload: response.data
          })
          trackActivity('Lease Management', {
            page_title: 'Global Search',
            event_type: 'Search',
            query: query,
            source: level,
            application: application ? application : 'None',
            result_count: response.data.data.pagination.total
          })
          if(noFilter){
          }else{
            dispatch(getFilterOptionsAc(data))
          }
        }
    })
    .catch(error => {
      dispatch({
        type: actions.GLOBAL_SEARCH_LOADER,
        payload: false
      })
    })
  }
}


export const downloadFilesAc = (params, item, msn) => {
  const data={
    type: item.type,
    object_uuid:[item.uuid]
  };
  let type =  params.type == 'aircraft' ? 'MSN' : params.type == 'engine' ? 'ESN' : params.type.toUpperCase();
  return (dispatch) => {
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });
    globalUnlimitedDownloadService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/download/', data)
    .then(response => {
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
      if(response.status != 206){
        if(response.data.statusCode){
          toastFlashMessage("Can not download file", "error");
        }else{
          const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
          const link = document.createElement('a');
          link.href = url;
          if(item.type == "file"){
            link.setAttribute('download',item.name);
          }else {
            link.setAttribute('download',type +'_'+ msn + '.zip');
          }
          document.body.appendChild(link);
          link.click();
        }
      }
    })
  }
}
