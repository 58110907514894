import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { mrAccounts } from '../../contractAddObject';
export const contrInvcInfoAc = (params) => {
  return (dispatch) => {
    globalGetService(params.type + '/' +params.aircraft_slug + '/contracts/' + params.contract_slug  + '/',{})
    .then(response => {
      dispatch({
        type: actions.RESERVE_RATE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_RESERVE_RATE_INVOICE,
          payload: {res: response.data.data, flag: false }
        });
      }
    });
  }
}

export const fetchMUICPInflationAc = (params) => {
  return (dispatch) => {
    globalGetService('/contracts/' + params.contract_slug  + '/inflation-rate/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MUICP_INFLATION_RATE_LIST,
          payload:response.data.data
        });
      }
    });
  }
}
export const fetchAnnualRatioAc = (params) => {
  return (dispatch) => {
    globalGetService('/contracts/' + params.contract_slug  + '/annual-ratio/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.ANNUAL_RATIO_LIST,
          payload:response.data.data
        });
      }
    });
  }
}


export const addMUICPInflationAc = (params,data) => {
  return (dispatch) => {
    globalPostService('contracts/' + params.contract_slug  + '/inflation-rate/',data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MUICP_INFLATION_ADD,
          payload:response.data.data
        });
        dispatch({
          type: actions.INFLATION_RATE_CRUD,
          payload: {type:'',data: {}, flag: false }
        });
      }
    });
  }
}

export const editMUICPInflationAc = (params,data,id) => {
  return (dispatch) => {
    globalPutService('contracts/' + params.contract_slug  + '/inflation-rate/'+id+'/',data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MUICP_INFLATION_UPDATE,
          payload:response.data.data
        });
        dispatch({
          type: actions.INFLATION_RATE_CRUD,
          payload: {type:'',data: {}, flag: false }
        });
      }
    });
  }
}


export const contrInvcInfoEditAc = (params, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESERVE_RATE_LOADER,
      payload: true
    });
    globalPutService(`contracts/${params.contract_slug}/reserve-invoice-config/`,data)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch(contrInvcInfoAc(params));
        toastFlashMessage(response.data.message, 'success');

      }
    })
  }
}

export const contrResvRateListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESERVE_RATE_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/reserve-accounts/', {})
    .then(response => {
      dispatch({
        type: actions.RESERVE_RATE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_RESERVE_RATE_LIST,
          payload: response.data.data
        });
      }
    });
  }
}

export const contrResvRateViewAc = (params ,id, index) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESERVE_RATE_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/reserve-accounts/' + id + '/',{})
    .then(response => {
      dispatch({
        type: actions.RESERVE_RATE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.CONTR_RESERVE_RATE_VIEW,
          payload: { res: response.data.data, index:index }
        });
      }
    });
  }
}

export const fetchRevisionHistoryAc = (id ) => {
  return (dispatch) => {
    dispatch({
      type: actions.RESERVE_RATE_LOADER,
      payload: true
    });
    globalGetService('audit/contracts/ctcontracts/?mr_review_audit_history=true&action=1&object_id='+id,{})
    .then(response => {
      dispatch({
        type: actions.RESERVE_RATE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.MR_REVISION_HISTORY,
          payload: response.data.data
        });
      }
    });
  }
}




export const contrResvRateEditAc = (params, data, type) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actions.RESERVE_RATE_LOADER,
        payload: true
      });

      if (type === 'add') {
        globalPostService('contracts/' + params.contract_slug + '/reserve-accounts/', data)
          .then(response => {
            dispatch({
              type: actions.RESERVE_RATE_LOADER,
              payload: false
            });
            if (response.data.statusCode === 200) {
              dispatch({
                type: actions.CONTR_RESERVE_RATE_ADD,
                payload: response.data.data
              });
              toastFlashMessage(response.data.message, 'success');
              resolve(response.data); 
            } else {
              reject(response.data.message); 
            }
          })
          .catch(error => {
            dispatch({
              type: actions.RESERVE_RATE_LOADER,
              payload: false
            });
            reject(error); 
          });
      } else {
        globalPutService('contracts/' + params.contract_slug + '/reserve-accounts/' + data.id + '/', data)
          .then(response => {
            dispatch({
              type: actions.RESERVE_RATE_LOADER,
              payload: false
            });
            if (response.data.statusCode === 200) {
              dispatch({
                type: actions.CONTR_RESERVE_RATE_EDIT,
                payload: response.data.data
              });
              dispatch({
                type: actions.MR_RATE_CRUD,
                payload: { type: '', data: {}, flag: false }
              });
              toastFlashMessage(response.data.message, 'success');
              resolve(response.data);
            } else {
              reject(response.data.message);
            }
          })
          .catch(error => {
            dispatch({
              type: actions.RESERVE_RATE_LOADER,
              payload: false
            });
            reject(error);
          });
      }
    });
  };
};


export const mrRateCrudFnAc = (params, type, id, index) => {
  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.MR_RATE_CRUD,
        payload: {type: type, data:mrAccounts , flag: true }
      });
    }else{
      dispatch({
        type: actions.RESERVE_RATE_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/reserve-accounts/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.RESERVE_RATE_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.MR_RATE_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
        }
      })
    }
  }
}
