import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../../../applications/contracts/Elements';
import { LeaseOptionList, LeaseOptionCRU } from '../components';
import { assetsType } from '../../../../constants';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getPlatformConstantsAc } from '../../../../shared_elements/actionCreators';
import { getLeaseOptionListApi ,deleteEolListApi} from '../apiServices';
import { sExportContractsAc } from '../../../actionCreators';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { leaseOptionHd } from '../';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import {contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class LeaseOptions extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list:[],
      deleteItem:{modal:false,data:null}
    }
    this.getLeaseOptionListApi = getLeaseOptionListApi.bind(this);
    this.deleteEolListApi = deleteEolListApi.bind(this);
  }
  componentDidMount(){
    this.getLeaseOptionListApi(this.props);
    this.props.getPlatformConstants();
    this.props.fetchContractInfo();
    trackActivity('Page Visited', {
      page_title: 'EOL (End of Lease) Options',
      event_type: 'Page Visited'
    })
  }
  getResponseBack = () => {
    this.getLeaseOptionListApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, deleteItem, pageLoader } = this.state;
    const { pltConstants, params, contractSummaryLoader, contractInfo } = this.props
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="EOL (End of Lease) Options" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='lease-option' primaryTab='technical-details' secondaryTab='eolOptions' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctleaseoptions/'
            params={params}
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            buttonText={ checkPermission('contracts','eol_options', 'C') ? <LeaseOptionCRU  contractInfo={this.props.contractInfo} pltConstants={pltConstants} getResponseBack={this.getResponseBack} /> : null}
          >
            { skeletonLoader ?
              (<STableLoader count={5} />):
              (<TableListComp
                  heads={leaseOptionHd}
                  data={list.map((item,index) =>
                    <LeaseOptionList
                      pltConstants={pltConstants}
                      contractInfo={this.props.contractInfo}
                      getResponseBack={this.getResponseBack}
                      key={index}
                      item={item}
                      deleteEolList={() => this.setState({deleteItem:{modal:true,data:item}})}
                    />
                  )}
                  noRecord={ list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              )
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteEolListApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:["lease_option_type"]})),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(LeaseOptions,['contracts','eol_options','R'])));
