import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import moment from 'moment';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from  'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import Pagination from "react-js-pagination";
import { ContractHeadertext, Header, ContractSideBar, WidgetCard, WidgetTitle, ContractBarLoader } from '../../Elements';
import { displayDateFormat, imgStoragePath, assetsType } from '../../../../constants';
import { removeEmptyKey, showCurrencyFormat, getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import FilterBar from '../../../../shared/FilterBar';
import { ListLoader, NoRecordFound  } from '../../../../shared';
import CashFlowListInfo from '../components/CashFlowListInfo';
import CashFlowList from '../components/CashFlowList';
import { loadCashFlowAssetViewAc, cashFlowListAc, cashFlowWidgetAc, exportContractCashflowAc } from '../actionCreators';
import { sExportContractsAc } from '../../../../shared/actionCreators';
import { contrResvRateListAc } from '../../MRRates/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import '../../assets/styles/CashFlow.scss';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import ReserveFundStatus from '../../shared/ReserveFundStatus';
import { Grid } from '@material-ui/core';
import { jetStreamInstance } from '../../../../lease_management/Leases';
class ContractCashFlow extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      appliedFilter: {},
      currentPage: 1,
      dropdownOpen: false,
      jspLessor: false,
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  componentDidMount(){
    let info = getLocalStorageInfo()
    if (info.defaultLessor.id == 442 || info.defaultLessor.id == 463) {
      this.setState({ jspLessor: true })
    }
    this.props.fetchAssetInfo();
    this.props.cashFowList({});
    this.props.cashFlowWidget();
    this.props.contrResvRateList();
  }
  toggleFilterList = () => {
    this.setState(prevState => ({
      ...prevState,
      toggleFilter: !prevState.toggleFilter
    }))
  }

  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  selectFilter = (value) => {
    this.setState(prevState => ({
      ...prevState,
      selectedFilterTitle: value,
      toggleFilter: false
    }))
  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.cashFowList(getQueryStr);
    this.setState({
      appliedFilter: filterParams,
      page:1
    });
  }
  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'transaction_reference_id':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Reserve Account: ', value: args[1].name,
              filterData: args[1].id
            }
          }
        }
        break;
      case 'transaction_type':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Transaction Type: ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
        case 'reserve_type':
          if (args[1]){
            filterParams ={
              ...filterParams,
              [args[0]]:{
                name:'Reserve Rate Type', value:args[1].label,
                filterData: args[1].value
              }
            }
          }
          break;
          case 'rental_type':
            if (args[1]){
              filterParams ={
                ...filterParams,
                [args[0]]:{
                  name:'Rental Rate Type', value:args[1].label,
                  filterData: args[1].value
                }
              }
            }  
            break;
      case 'currency':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Currency: ', value: args[1].label,
              filterData: args[1].value
            }
          }
        }
        break;
      case 'date':
        let date = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
        filterParams = {
          ...filterParams,
          [args[0]]: {
            name:'Date',
            value: date,
            startDate: args[1] ? args[1]:'',
            endDate: args[2]?args[2]:''
          }
        }
      default:
       break;
    }
    this.setState({
      appliedFilter: filterParams,
      page: 1
    });
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    this.props.cashFowList(getQueryStr);
  }

  updateAssetQueryUrl = (queryFilter) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    location.query= queryFilter;
    browserHistory.push(location);
  }

  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if(key == 'date'){
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      }else{
        queryFilter[key] = params[key].filterData
      }
    });
    this.updateAssetQueryUrl(queryFilter);
    return queryFilter;
  }
  handlePageChange = (page) => {
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter));
    getQueryStr['page'] = page;
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    this.props.cashFowList(getQueryStr);
  }
  render() {
    const { invoiceList, cashFlowList, ctrShortInfo, cashflowWidget, cashflowLoader, mrRatesList } = this.props;
    const filterMenu = {
      'Currency Type': {
        'inputType': 'dropdown',
        'keyParam': 'currency',
        'placeholder': 'Select Currency Type',
        'options': [{label: 'USD', value: 'USD'}, {label: 'EURO', value: 'EUR'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      },
      'Reserve Account Type': {
        'inputType': 'dropdown',
        'keyParam': 'transaction_reference_id',
        'placeholder': 'Select Reserve Account Type',
        'options': mrRatesList,
        'labelKey': 'name',
        'valueKey': 'id',
      },
      'Reserve Rate Type':{
        'inputType': 'dropdown',
        'keyParam':'reserve_type',
        'placeholder': 'Select Reserve Rate Type',
        'options': this.state.jspLessor? [{ label: 'ESP', value: '2' }, { label: "JSP", value: '4' }, { label: 'LSP', value: '3' }, { label: 'MR', value: '1' }   ]: [{ label: 'ESP', value: '2' },{ label: 'LSP', value: '3' }, { label: 'MR', value: '1' } ],
        'labelKey': 'label',
        'valueKey': 'value',
      },
      'Transaction Type':{
        'inputType': 'dropdown',
        'keyParam': 'transaction_type',
        'placeholder': 'Select Transaction Type',
        'options': [{label: 'Credit Note Transaction', value: '5'}, {label: 'Debit Note Transaction', value: '4'},{label: 'MR Transaction', value: '1'},{label: 'Penalty Transaction', value: '3'} ,{label: 'Rental Transaction', value: '2'}],
        'labelKey': 'label',
        'valueKey': 'value',
      },
    };
    const rentalFilter={
      'Rental Rate Type':{
        'inputType': 'dropdown',
        'keyParam':'rental_type',
        'placeholder': 'Select Rental Rate Type',
        'options': this.state.jspLessor? [{ label: 'ESP', value: '1' }, { label: "JSP", value: '3' }, { label: 'LSP', value: '2' }  ]: [{ label: 'ESP', value: '1' },{ label: 'LSP', value: '2' } ],
        'labelKey': 'label',
        'valueKey': 'value',
        'visible': this.state.jspLessor,
      }
    }
    return (
      <div className="contract-container">
       <div className="mr-rate-wrapper" style={{marginLeft: '60px'}}>
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application="Cashflow" type="brief" params= {this.props.params} />
        {cashflowLoader || this?.props?.contractSummaryLoader? <ListLoader />:null}
        <div className="cash-flow-tab">
          <Row className="invoice-cards flex-not-centered">
            {jetStreamInstance?
            <Grid md="12">
            <h5 className="uti-heading main-reserve-fund-status" >Reserve Fund Details</h5>
            </Grid>:null}
              <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para">Total MR Fund</WidgetTitle>
                {cashflowWidget?.reserve_accounts?.mr_reserve_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.reserve_accounts.mr_reserve_account ? (
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.reserve_accounts.mr_reserve_account.mr_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.reserve_accounts.mr_reserve_account && cashflowWidget.reserve_accounts.mr_reserve_account.mr_as_of_date ?
                            moment(cashflowWidget.reserve_accounts.mr_reserve_account.mr_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
              </WidgetCard>
              {this.state.jspLessor?
              <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para">Total ESP Fund</WidgetTitle>
                {cashflowWidget?.reserve_accounts?.esp_reserve_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.reserve_accounts.esp_reserve_account? (
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.reserve_accounts.esp_reserve_account.esp_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.reserve_accounts.esp_reserve_account && cashflowWidget.reserve_accounts.esp_reserve_account.esp_as_of_date ?
                            moment(cashflowWidget.reserve_accounts.esp_reserve_account.esp_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
              </WidgetCard>:null}
              {this.state.jspLessor?
              <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para">Total LSP Fund</WidgetTitle>
                {cashflowWidget?.reserve_accounts?.lsp_reserve_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.reserve_accounts.lsp_reserve_account? (
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.reserve_accounts.lsp_reserve_account.lsp_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.reserve_accounts.lsp_reserve_account && cashflowWidget.reserve_accounts.lsp_reserve_account.esp_as_of_date ?
                            moment(cashflowWidget.reserve_accounts.esp_reserve_account.lsp_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
              </WidgetCard>: null}
              {this.state.jspLessor ?
                  <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                    <WidgetTitle className="para">Total JSP Fund</WidgetTitle>
                    {cashflowWidget?.reserve_accounts?.jsp_reserve_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.reserve_accounts.jsp_reserve_account? (
                          <NumericLabel params={showCurrencyFormat()}>
                            {cashflowWidget.reserve_accounts.jsp_reserve_account.jsp_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.reserve_accounts.jsp_reserve_account && cashflowWidget.reserve_accounts.jsp_reserve_account.jsp_as_of_date ?
                            moment(cashflowWidget.reserve_accounts.jsp_reserve_account.jsp_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
                  </WidgetCard>: null}
               {jetStreamInstance?   
              <Grid md="12" >  
              <h5 className="uti-heading main-reserve-fund-status" >Rental Fund Details</h5>
               </Grid>:null}
              <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                <WidgetTitle className="para">Total Monthly Rental Fund</WidgetTitle>
                <div>
                  <p>
                    {cashflowWidget.rental_new ?
                      cashflowWidget.rental_new.length ?
                        cashflowWidget.rental_new.map((rental, index) =>
                          <div>
                            <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                          </div>
                        ) : 'US$0.00' : 'US$0.00'
                    }
                    <span style={{ color: '#ffffff !important', opacity: '0.5' }} className="as-of-date">
                      As of {
                        cashflowWidget.rental_detail_as_of_date ?
                          moment(cashflowWidget.rental_detail_as_of_date).format(displayDateFormat) : 'date --'
                      }
                    </span>
                  </p>
                </div>
              </WidgetCard>   
                {this.state.jspLessor ?
                  <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                    <WidgetTitle className="para">Total ESP Fund</WidgetTitle>
                    {cashflowWidget?.rental_accounts?.esp_rental_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.rental_accounts?.esp_rental_account? (
                          <NumericLabel params={showCurrencyFormat(cashflowWidget.rental_new[0]?.currency)}>
                            {cashflowWidget.rental_accounts?.esp_rental_account.esp_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.rental_accounts?.esp_rental_account && cashflowWidget.rental_accounts?.esp_rental_account.esp_as_of_date ?
                            moment(cashflowWidget.rental_accounts?.esp_rental_account.esp_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
                  </WidgetCard>: null}  
                {this.state.jspLessor ?
                  <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                    <WidgetTitle className="para">Total LSP Fund</WidgetTitle>
                    {cashflowWidget?.rental_accounts?.lsp_rental_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.rental_accounts?.lsp_rental_account? (
                          <NumericLabel params={showCurrencyFormat(cashflowWidget.rental_new[0]?.currency)}>
                            {cashflowWidget.rental_accounts?.lsp_rental_account.lsp_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.rental_accounts?.lsp_rental_account && cashflowWidget.rental_accounts?.lsp_rental_account.jsp_as_of_date ?
                            moment(cashflowWidget.rental_accounts?.lsp_rental_account.lsp_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
                  </WidgetCard>: null}      

              {this.state.jspLessor ?
                  <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                    <WidgetTitle className="para">Total JSP Fund</WidgetTitle>
                    {cashflowWidget?.rental_accounts?.jsp_rental_account && (
                  <div>
                    <p>
                      {
                        cashflowWidget.rental_accounts?.jsp_rental_account? (
                          <NumericLabel params={showCurrencyFormat(cashflowWidget.rental_new[0]?.currency)}>
                            {cashflowWidget.rental_accounts?.jsp_rental_account.jsp_val}
                          </NumericLabel>
                        ) : '$0.00'
                      }
                      <span style={{ color: '#ffffff', opacity: '0.5' }} className="as-of-date">
                        As of {
                          cashflowWidget.rental_accounts?.jsp_rental_account && cashflowWidget.rental_accounts?.jsp_rental_account.jsp_as_of_date ?
                            moment(cashflowWidget.rental_accounts?.jsp_rental_account.jsp_as_of_date).format(displayDateFormat) : 'date --'
                        }
                      </span>
                    </p>
                  </div>
                )}
                  </WidgetCard>: null}   
          </Row>
        </div>
        <div>
          <ReserveFundStatus />
        </div>
        <div className="cash-flow-wrapper">
          <div  className="download-block" style={{ textAlign: 'right', marginBottom: '15px'}}>
            {checkPermission('contracts','cashflow','EXP') && cashFlowList?.list?.length ?
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret size="sm">
                   <span><img width="12" src={exportIcon}  alt="export"/> Export </span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem  onClick={() => this.props.exportCashFlow(ctrShortInfo, 'pdf', this.state.appliedFilter)}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                    <DropdownItem onClick={() => this.props.exportCashFlow(ctrShortInfo, 'xls', this.state.appliedFilter)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                    <DropdownItem onClick={() => this.props.exportCashFlow(ctrShortInfo, 'docx', this.state.appliedFilter)} ><img src={imgStoragePath+"docs_icon.png"} alt="img"/>Doc</DropdownItem>
                    <DropdownItem onClick={() => this.props.exportCashFlow(ctrShortInfo, 'csv', this.state.appliedFilter)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>CSV</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              :null
            }
          </div>
          <div className="mobile-asset-list">
            <div className="asset-list-filter-sort" >
                <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={jetStreamInstance?{...filterMenu, ...rentalFilter}:filterMenu} changeFilterFn={this.changeFilterFn}/>
            </div>
            <CashFlowListInfo>
                { cashFlowList.list.map((cashflow, index) =>
                  <CashFlowList key={index} index={index} cashflow={cashflow} cashflows={cashFlowList.list} contract_slug={this.props.params.contract_slug} /> )
                }
              </CashFlowListInfo>
          </div>

          {
            !cashflowLoader && !cashFlowList.list.length ? <NoRecordFound style={{margin:'0px'}}description="Get Started by generating Maintenance & Rental invoices" />:null
          }
          {
            cashFlowList.list.length ?
            <div style={{padding: '0 0 40px 0'}}>
               <Pagination
                activePage={this.state.page}
                itemsCountPerPage={20}
                totalItemsCount={cashFlowList.pagination.total ? cashFlowList.pagination.total:0}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>:null
          }

        </div>
      </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.sharedReducers.contractSummaryLoader,
  cashFlowList: state.ContrCashFlowReducer.cashFlowList,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  cashflowWidget: state.ContrCashFlowReducer.cashflowWidget,
  cashflowLoader: state.ContrCashFlowReducer.cashflowLoader,
  mrRatesList: state.ContrMRRateReducer.mrRatesList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadCashFlowAssetViewAc(ownProps.params)),
    cashFowList: (filter) => dispatch(cashFlowListAc(ownProps, filter)),
    cashFlowWidget: (filter) => dispatch(cashFlowWidgetAc(ownProps, filter)),
    exportCashFlow: (contract, fileType, filter) => dispatch(exportContractCashflowAc(ownProps, contract, fileType, filter)),
    contrResvRateList: () => dispatch(contrResvRateListAc(ownProps.params)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractCashFlow, ['contracts','cashflow','R'])));
