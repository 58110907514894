export const addInduction = {
  terms: '',
  remarks: '',
  asset_conditions_for_contract: null
}
export const inductionCondHd = [
  { label: 'Clause', sortOption: false },
  { label: 'Terms', sortOption: false },
  { label: 'Remarks', sortOption: false },
  { label: 'Action', sortOption: false }
]
export const errorCode = {
  asset_conditions_for_contract:{
    0:'',
    1: 'Please select Clause'
  },
  asset_conditions_for_contractObj: {
    required: true
  },
  terms: {
    0:'',
    1: 'Please enter Terms'
  },
  termsObj: {
    required: true
  }
}

export const  addDeliveryCondition = {
  asset_conditions_for_contract: null,
  actual: '',
  terms: '',
  remarks: '',
  exceptions_done: ''
}

export const deliveryConditionHd = [
  { label: 'Clause', sortOption: false },
  { label: 'Terms', sortOption: false },
  { label: 'Post Delivery', sortOption: false },
  { label: 'Exception', sortOption: false },
  { label: 'Attachment(s)', sortOption: false },
  { label: 'Action', sortOption: false ,actionCell:'right'}
]
export const errorCodeDeliveryCondition = {
  asset_conditions_for_contract:{
    0:'',
    1: 'Please select the Clause'
  },
  asset_conditions_for_contractObj: {
    required: true
  },
  terms: {
    0:'',
    1: 'Please enter the terms'
  },
  termsObj: {
    required: true
  }
}

export const addReturnCondition = {
  asset_conditions_for_contract: null,
  exceptions_done: '',
  terms: '',
  flags: false,
  remarks: ''
}
export const returnCondHd = [
  { label: 'Clause', sortOption: false },
  { label: 'Terms', sortOption: false },
  { label: 'Flag', sortOption: false },
  { label: 'Attachment(s)', sortOption: false },
  { label: 'Action', sortOption: false, actionCell:'right'},
];
export const errorCodeReturnCondition = {
  asset_conditions_for_contract:{
    0:'',
    1: 'Please select Clause'
  },
  asset_conditions_for_contractObj: {
    required: true
  },
  terms: {
    0:'',
    1: 'Please enter Terms'
  },
  termsObj: {
    required: true
  }
}
