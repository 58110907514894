import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, Button, DialogActions, Chip, List, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import { checkPermission, toastFlashMessage } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import { displayDateFormatShort } from '../../../../constants';
import SubleaseCRU from './SubleaseCRU';
import { globalDeleteService } from '../../../../utils_v2/globalApiServices';
import { DeletePopUp, PageLoader } from '../../../../shared_elements';
import { titanInstance } from '../../../../shared_elements/components';
export default function SubleaseList({item, previewDetail, getResponseBack, contractInfo,deleteSubLease}){
  const [showMore, setShowMore] = useState({});
  const [previewDocs, setPreviewDocs] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);
  const attachmentsToShow = Array.isArray(item?.attachments) ? (showAll ? item.attachments : item.attachments.slice(0, 2)) : [];
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return(
    <>
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.sublease_lessee}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>
      {item?.details && item?.details?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.details.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.details.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.details.replace(/\n/g, '<br />') }} />
          </div>
        )}
        </TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.end_date ? moment(item.end_date).format(displayDateFormatShort): '--'}</TableCell>
      {titanInstance?
          <TableCell>
            {attachmentsToShow ? attachmentsToShow && attachmentsToShow.map((file, index) => (
              <Tooltip key={index} title={`${file.name}`} arrow placement="top">
                <Chip
                  style={{ marginRight: '4px', marginTop:item?.attachments?.length > 2 ? '7px':'',width:'50px', height: '15px', fontSize: '10px' }}
                  variant="outlined"
                  size="small"
                  label={file.name.substring(0, 14) + '...'}
                  clickable
                  color="primary"
                  onClick={(e) => { e.preventDefault(); setPreviewDocs(file); }}
                />
              </Tooltip>
            )) : '--'}
            {item?.attachments?.length > 2 && (
              <div style={{ marginTop: '10px' }}>
                <Chip
                  label={openAttachment ? 'Show Less' : 'Show More'}
                  clickable
                  color='primary'
                  onClick={() => setOpenAttachment(!openAttachment)}
                  style={{ cursor: 'pointer', height: '15px', fontSize: '9px' }}
                />
              </div>
            )}
          </TableCell> : null}
      <TableCell>
        <SubleaseCRU getResponseBack={getResponseBack} m="V" id={item.id} contractInfo={contractInfo}/>
        {checkPermission('contracts','sublease', 'D') ? <DeleteIcon fontSize='small' color='secondary' style= {{cursor:'pointer'}} onClick={()=>deleteSubLease(item.id)}/>: null}
      </TableCell>
      </TableRow>
      {previewDocs ? (
        <Dialog open={true} fullScreen >
          <DialogTitle>{previewDocs.name}</DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            {previewDocs.extension === 'csv' ? (
              <object
                data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ width: '100%', height: '100%' }} />
            ) : ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ? (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewDocs.url)}`} width="100%" height="100%" frameBorder="0" />
            ) : ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ? (
              <img src={previewDocs?.url} alt="Attachment" style={{ width: '100%', height: 'auto' }} />
            ) : previewDocs.extension === 'pdf' ? (
              <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ width: '100%', height: '100%' }} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={(e) => { window.open(previewDocs.url); e.stopPropagation(); }}>Download</Button>
            <Button variant="contained" color="primary" onClick={() => setPreviewDocs(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {openAttachment &&
        <Dialog open={openAttachment} className='mou-dialog'>
          <DialogTitle>Attachment(s)</DialogTitle>
          <DialogContent dividers>
            <List>
              {item?.attachments?.map((file, index) => (
                <ListItem button key={index} style={{ backgroundColor: index % 2 === 0 ? '#ebeff6' : '#fff' }} onClick={() => setPreviewDocs(file)}>
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' color='primary' size='small' onClick={() => setOpenAttachment(false)}>Close</Button>
          </DialogActions>
        </Dialog>}
    </>
  )
}
