import React, { Component } from 'react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { escalationIntervalLst, intervalList, displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import { ContrListCol } from '../../Elements';
import { titanInstance } from '../../../../shared_elements/components';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
export default class ReserveRateRow extends Component{
  constructor(props){
    super(props);
    this.state = {
      previewDocs: '',
      showAll: false,
      openAttachment: false
    };
  }

  previewDoc(item) {
    this.setState({ previewDocs: item });
  }
  toggleAttachments = () => {
    this.setState((prevState) => ({ openAttachment: !prevState.openAttachment }));
  };
  render(){
    const { rate, index } = this.props;
    const { previewDocs, showAll, openAttachment } = this.state;
    const attachmentsToShow = Array.isArray(rate?.severity_table) ? (showAll ?rate?.severity_table : rate?.severity_table.slice(0, 2)) : [];

    const rateObj = [
      {
        value: (
          <div style={{ display: 'grid' }}>
            <span>{rate.name ? rate.name : '--'}</span>
            {titanInstance && rate?.severity_table?.length > 0 && (
              <span>
                {attachmentsToShow&&attachmentsToShow.map((file, index) => (
                  <Tooltip key={index} title={file.name} arrow placement="top">
                    <Chip
                      style={{ marginRight: '4px', marginTop: '7px', height: '15px', fontSize: '10px' }}
                      variant="outlined"
                      size="small"
                      label={<p style={{ fontSize: '10px' }}>{file.name.length > 14 ? file.name.substring(0, 14) + '...' : file.name}</p>}
                      clickable
                      color="primary"
                      onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(file) }}
                    />
                  </Tooltip>
                ))}
                {rate?.severity_table?.length > 2 && (
                  <div style={{ marginTop: '10px' }}>
                    <Chip
                      label={<p style={{ color: '#fff', fontSize: '10px' }}>{openAttachment ? 'Show Less' : 'Show More'}</p>}
                      clickable
                      color='primary'
                      onClick={(e)=>{e.stopPropagation() ; this.toggleAttachments()}}
                      style={{ cursor: 'pointer', height: '15px', fontSize: '9px' }}
                    />
                  </div>
                )}
              </span>
            )}
          </div>
        ),
      },
      { value: rate.account_type?.label || "--" },
      { value: rate.interval?.label || '--' },
      {
        value:
          rate.reserve_rate_per_interval >= 0
            ? rate?.interval_condition_status || rate?.pbh_status || rate?.is_adjustment_factor ? (
              <span style={{ textDecoration: 'underline', color: 'blue' }}>View Rate</span>
            ) : (
              <NumericLabel params={showCurrencyFormat(rate.currency)}>{rate.reserve_rate_per_interval}</NumericLabel>
            )
            : '--',
      },
      { value: rate.rate_start_date ? moment(rate.rate_start_date).format(displayDateFormat) : '--' },
      { value: rate.rate_end_date ? moment(rate.rate_end_date).format(displayDateFormat) : '--' },
      { value: rate.rate_escalation_percentage ? rate.rate_escalation_percentage + '%' : '--' },
      { value: rate.rate_escalation_interval?.label || '--' },
    ];

    return (
      <>
        <tr style={{ opacity: rate.status === 0 ? 0.5 : 1 }} onClick={() => this.props.viewMrRate(rate.id, index)}>
          {rateObj.map((rate, idx) => (
            <ContrListCol colObj={rate} key={idx} />
          ))}
        </tr>

        {previewDocs && (
          <Dialog open={!!previewDocs} fullScreen>
            <DialogTitle>{previewDocs.name}</DialogTitle>
            <DialogContent style={{ height: window.innerHeight - 100 }}>
              {previewDocs.extension?.toLowerCase() === 'csv' ? (
                <object id="myObject" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ width: '100%', height: '100%' }} />
              ) : ['xls', 'xlsx', 'docx'].includes(previewDocs.extension?.toLowerCase()) ? (
                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewDocs.url)}`} width="100%" height="100%" frameBorder="0" />
              ) : ['jpg', 'jpeg', 'png'].includes(previewDocs.extension?.toLowerCase()) ? (
                <img src={previewDocs?.url} alt="Attachment" style={{ width: '100%', height: 'auto' }} />
              ) : previewDocs.extension?.toLowerCase() === 'pdf' ? (
                <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100%' }}>
                  <param name="type" value="text/html" />
                </object>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={(e) => { window.open(previewDocs.url); e.stopPropagation(); }}>
                Download
              </Button>
              <Button variant="contained" color="primary" onClick={() => this.setState({ previewDocs: '' })}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {openAttachment &&
          <Dialog open={openAttachment} className='mou-dialog'>
            <DialogTitle>Attachment(s)</DialogTitle>
            <DialogContent dividers>
              <List>
                {rate?.severity_table?.map((file, index) => (
                  <ListItem button key={index} style={{ backgroundColor: index % 2 === 0 ? '#ebeff6' : '#fff' }} onClick={() => this.previewDoc(file)}>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' color='primary' size='small' onClick={this.toggleAttachments}>Close</Button>
            </DialogActions>
          </Dialog>
        }        
      </>
    );
  }
}
