import moment from 'moment';
import { browserHistory } from 'react-router';
import { globalGetService, globalExportService, globalPostService, globalPutService, globalDeleteService, globalSftpGetService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../utils_v2';
import { trackActivity } from '../../utils_v2/mixpanel';
export function getCashflowsApi(props, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = Object.assign({}, query);
  globalGetService(`contracts/${props.params.contract_slug}/cashflow-ledger-list/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      let payout = Object.assign({},response.data.data);
      delete query.page;
      delete query.per_page;
      payout = {
        ...payout,
        pagination:{total:response.data.data.pagination.total, per_page:10, page:response.data.data.pagination.current_page}
      }
      this.setState({
        cashflows:payout,
        filter:query
      })
    }
  })
}

export function getFleetCashflowApi(props, query, loaderType, dataType){
  this.setState({[loaderType]:true});
  let filterQuery = Object.assign({}, query);
  globalGetService(`contracts/v2/list-cashflow/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.sort;
      delete query.sort_by;
      this.setState({
        cashflow:response.data.data,
        filter:query
      });
      if(dataType){
        this.setState({open:true});
      }
    }
  })
}

export function getCashflowsWidgetApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/cashflow/widget/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({cashflowWidget: response.data.data});
    }
  })
}
export function exportCashflowsApi(props, query){
  this.setState({pageLoader:true});
  let filterQuery = Object.assign({}, query);
  globalExportService(`contracts/${props.params.contract_slug}/cashflow-ledger-list/`, filterQuery)
  .then(response => {
    this.setState({pageLoader:false});
    downloadFileType(response.data, 'Cashflows', filterQuery.download);
    this.props.enqueueSnackbar('Cashflow list exported Successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function exportFleetCashflowsApi(props, query){
  this.setState({pageLoader:true});
  let filterQuery = Object.assign({}, query);
  //*********** debit_amount range code end ***********
  globalExportService(`contracts/v2/list-cashflow/`, filterQuery)
  .then(response => {
    this.setState({pageLoader:false});
    trackActivity('Lease Management', {
      page_title: 'Fleet Cashflow List',
      event_type:'Report sent to email',
      file_type: filterQuery.download
    })
    this.props.enqueueSnackbar("Request Queued!! You'll receive the requested file on your registered email shortly.", {variant: 'success', hideIconVariant:true, anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function getCashflowAccTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/cashflow-reserve/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        cashflowAccTypeGraph: response.data.data,
        filterAccType: query
      })
    }
  })
}
export function getCashflowRenatlAccTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/cashflow-rental/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        cashflowRentalAccTypeGraph: response.data.data,
        filterAccType: query
      })
    }
  })
}

export function geCashflowTransTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/cashflow-synopsis/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        cashflowTransTypeGraph: response.data.data,
        filterTransType: query
      })
    }
  })
}

export function getCashflowOwnerGraphApi(props={}, genericFilter, query, loader){
  this.setState({[loader]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/cashflow-owner/`, filterQuery)
  .then(response => {
    this.setState({[loader]:false});
    if(checkApiStatus(response)){
      delete query.page;
      this.setState({
        cashflowOwnerGraphData: response.data.data,
        filterOwnerGraph: query
      })
    }
  })
}

export function getCashflowLessorGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/cashflow-lessor/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        cashflowlessorGraphData: response.data.data,
        filterLessorGraph: query
      })
    }
  })
}

export function getAssetIdentifierApi(assetIdentifier, stateType, query={}){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${assetIdentifier}/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({[stateType]:response.data.data})
    }
  })
}









