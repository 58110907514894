import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader,  } from '../../../../shared_elements';
import { PartyList, PartyCRU } from '../components';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import { assetsType } from '../../../../constants';
import { getPlatformConstantsAc } from '../../../../shared_elements/actionCreators';
import { sExportContractsAc } from '../../../actionCreators';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { getPartiesApi ,deletePartylistApi} from '../apiServices';
import { partyHd } from '../';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class Parties extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      formSubmitLoader: false,
      list:[],
      deleteItem:{modal:false, data:null}
    }
    this.getPartiesApi = getPartiesApi.bind(this);
    this.deletePartylistApi = deletePartylistApi.bind(this);
  }
  componentDidMount(){
    this.getPartiesApi(this.props);
    this.props.fetchContractInfo();
    this.props.getPlatformConstants();
    trackActivity('Lease Management', {
      page_title: 'Parties',
      event_type: 'Page Visited'
    })
  }
  getResponseBack = () => {
    this.getPartiesApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, pageLoader, deleteItem } = this.state;
    const { pltConstants, contractSummaryLoader, contractInfo } = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Parties" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='parties' primaryTab='basic-betails' secondaryTab='party' />
          <ContractWrapper
            revision={true}
            url="audit/contracts/ctparties/"
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            buttonText={ checkPermission('contracts','party', 'C') ? <PartyCRU pltConstants={pltConstants} getResponseBack={this.getResponseBack} contractInfo={contractInfo}/>:null}
          >
            { skeletonLoader ?
              (<STableLoader count={8} />):
              <TableListComp
                heads={partyHd}
                data={list.map((item,index) =>
                  <PartyList
                    getResponseBack={this.getResponseBack}
                    pltConstants={pltConstants}
                    key={index}
                    item={item}
                    contractInfo={contractInfo}
                    deletePartylist={() => this.setState({deleteItem:{modal:true,data:item}})}
                  />
                )}
                noRecord={ list.length ? null:
                  <EmptyCollection
                    title="No records found"
                  />
                }
              />
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deletePartylistApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:["party_role"]})),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Parties,['contracts','party', 'R'])))
