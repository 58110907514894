import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { TableListComp, PageLoader } from '../../../../shared_elements';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import { CapeTownList } from '../components';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { assetsType } from '../../../../constants';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { sExportContractsAc } from '../../../actionCreators';
import { editCapeTownConApi, getCapeTownConListApi } from '../apiServices';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class CapeTownConvention extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      cape_town_clauses: '',
      error: {},
      flag: false
    }
    this.getCapeTownConListApi = getCapeTownConListApi.bind(this);
    this.editCapeTownConApi = editCapeTownConApi.bind(this);
  }
  componentDidMount() {
    this.getCapeTownConListApi(this.props);
    this.props.fetchContractInfo();
    trackActivity('Lease Management', {
      page_title: 'Cape Town Convention',
      event_type: 'Page Visited'
    })
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState({ cape_town_clauses: value });
  }
  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render() {
    const { list, cape_town_clauses, pageLoader, flag } = this.state;
    const { params, contractSummaryLoader, contractInfo } = this.props;
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Delivery Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='cape-town-convention' primaryTab='basic-betails' secondaryTab='capeTownConvention' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctcontracts/'
            params={params}
            queryParams={{ action: 1, object_id: this.props.params.contract_slug, params: 'cape_town_clauses' }}
          >
            <div className="cape-town-convention">
              <h2 className="cape-town-convention-header">
                Cape Town Convention
                {
                  checkPermission('contracts','cape_town_convention', 'U') ?
                    <span className="icon-blk">
                      {contractInfo?.hdlineInfo?.archived_asset == false?
                      flag ?
                        <CancelIcon className="cancel-icon" color="primay" onClick={() => { this.setState({ flag: false }); this.getCapeTownConListApi(this.props); }} /> :
                        <EditIcon className="edit-icon" color="primay" onClick={() => this.setState({ flag: true })} />
                        :null
                      }
                    </span>
                    : null}
              </h2>
              <TableListComp
                heads={[
                  { label: 'Description', sortOption: false }
                ]}
                data={<CapeTownList onFieldChange={this.onFieldChange} flag={flag} cape_town_clauses={cape_town_clauses} />}
              />
              {flag ?
                <ul className="list-inline cta-blk">
                  <li className="list-inline-item">
                    <Button color="primary" onClick={() => { this.setState({ flag: false }); this.getCapeTownConListApi(this.props); }}>Cancel</Button>
                  </li>
                  <li className="list-inline-item">
                    <Button color="primary" variant="contained" onClick={() => this.editCapeTownConApi(this.props, { cape_town_clauses: cape_town_clauses })}>Save Changes</Button>
                  </li>
                </ul> : null
              }
            </div>
            {pageLoader || contractSummaryLoader ? <PageLoader /> : null}
          </ContractWrapper>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(CapeTownConvention,['contracts','cape_town_convention', 'R']))));
