import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { WidgetCard, WidgetTitle } from '../Elements';
import { loadCashFlowAssetViewAc, exportContractCashflowAc } from '../../../applications/contracts/CashFlows/actionCreators';
import { getLocalStorageInfo, showCurrencyFormat } from '../../../utils';
import '../../../applications/contracts/assets/styles/CashFlow.scss';
class ReserveFundStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appliedFilter: {},
      currentPage: 1,
      dropdownOpen: false,
      jspLessor: false,
    }
  }
  componentDidMount() {
    this.props.fetchAssetInfo();
    let info = getLocalStorageInfo()
    if (info.defaultLessor.id == 442 || info.defaultLessor.id == 463) {
      this.setState({ jspLessor: true })
    }
  }
  render() {
    const { ctrShortInfo, cashflowWidget } = this.props;
    return (
      <>
        {

          ctrShortInfo.asset_type === 1 || ctrShortInfo.asset_type === 2 ?
            <>
              <h5 className="uti-heading main-reserve-fund-status" >Reserve fund status</h5>
              <div className="cash-flow-tab">
                <Row className="invoice-cards flex-not-centered">
                  {
                    cashflowWidget.engines && Object.keys(cashflowWidget.engines).length ?
                      Object.keys(cashflowWidget.engines).map((engine, index) =>
                        <WidgetCard key={index} md="2" className="padding-right-0 padding-left-xs-0">
                          <WidgetTitle className="para">{cashflowWidget.engines[engine].name}</WidgetTitle>
                          <div>
                            <h6>PRSV</h6>
                            <p>
                              {
                                <NumericLabel params={showCurrencyFormat()}>
                                  {cashflowWidget.engines[engine].prsv}
                                </NumericLabel>
                              }
                            </p>
                          </div>
                          <div>
                            <h6>LLP Replacement</h6>
                            <p>
                              {
                                <NumericLabel params={showCurrencyFormat()}>
                                  {cashflowWidget.engines[engine].llp}
                                </NumericLabel>
                              }
                            </p>
                          </div>
                          {cashflowWidget.engines[engine].cold_section ?
                            <div>
                              <h6>Cold Section</h6>
                              <p>
                                {
                                  <NumericLabel params={showCurrencyFormat()}>
                                    {cashflowWidget.engines[engine].cold_section}
                                  </NumericLabel>
                                }
                              </p>
                            </div> : null
                          }
                        </WidgetCard>
                      ) :  
                      <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                      <WidgetTitle className="para">Engine</WidgetTitle>
                      <div>
                        <h6>PRSV</h6>
                        <p>{<NumericLabel params={showCurrencyFormat()}>00.00</NumericLabel>}</p>
                      </div>
                      <div>
                        <h6>LLP Replacement</h6>
                        <p>{<NumericLabel params={showCurrencyFormat()}>00.00</NumericLabel>}</p>
                      </div>
                    </WidgetCard>
                  }

                  {
                    cashflowWidget.airframe_events && Object.keys(cashflowWidget.airframe_events).length ?
                      <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                        <WidgetTitle className="para"> Airframe </WidgetTitle>
                        {Object.keys(cashflowWidget.airframe_events).map((program, index) =>
                          <div key={index}>
                            <h6>{program}</h6>
                            <p>
                              <NumericLabel params={showCurrencyFormat()}>
                                {cashflowWidget.airframe_events[program]}
                              </NumericLabel>
                            </p>
                          </div>
                        )}
                      </WidgetCard> : 
                        ctrShortInfo.asset_type === 1 ?
                        <WidgetCard md="3" className="padding-right-0 padding-left-xs-0">
                        <WidgetTitle className="para"> Airframe </WidgetTitle>
                            <p><NumericLabel params={showCurrencyFormat()}>00.00</NumericLabel></p>
                        </WidgetCard>:null                    
                  }
                  {
                    ctrShortInfo.asset_type == 1 && cashflowWidget && typeof (cashflowWidget.apu) == "number" ?
                      <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                        <WidgetTitle className="para">APU</WidgetTitle>
                        <div>
                          <p>
                            {
                              cashflowWidget.apu && parseFloat(cashflowWidget.apu) ?
                                <NumericLabel params={showCurrencyFormat()}>
                                  {cashflowWidget.apu}
                                </NumericLabel> : '$0.00'
                            }
                          </p>
                        </div>
                      </WidgetCard> :
                      null
                    // typeof(cashflowWidget.apu) == "object" ? cashflowWidget.apu.length?
                    //   <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                    //     <WidgetTitle className="para">APU</WidgetTitle>
                    //     <div>
                    //       <p>
                    //         {
                    //           cashflowWidget.apu && parseFloat(cashflowWidget.apu) ?
                    //             <NumericLabel params={showCurrencyFormat()}>
                    //               {cashflowWidget.apu}
                    //             </NumericLabel> : '$0.00'
                    //         }
                    //       </p>
                    //     </div>
                    //   </WidgetCard> :
                    //   null:
                    //   typeof(cashflowWidget.apu) == "number" && cashflowWidget.apu ?
                    //   <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                    //     <WidgetTitle className="para">APU</WidgetTitle>
                    //     <div>
                    //       <p>
                    //         {
                    //           cashflowWidget.apu && parseFloat(cashflowWidget.apu) ?
                    //             <NumericLabel params={showCurrencyFormat()}>
                    //               {cashflowWidget.apu}
                    //             </NumericLabel> : '$0.00'
                    //         }
                    //       </p>
                    //     </div>
                    //   </WidgetCard> :null
                    //   : null
                  }
                  {
                    [1, 4].includes = ctrShortInfo.asset_type && cashflowWidget.lg ?
                      Object.keys(cashflowWidget.lg).map(i =>
                        <WidgetCard md="2" className="padding-right-0 padding-left-xs-0">
                          <WidgetTitle className="para">{cashflowWidget.lg[i].name}</WidgetTitle>
                          <div>
                            <p>
                              {
                                cashflowWidget.lg && parseFloat(cashflowWidget.apu) ?
                                  <NumericLabel params={showCurrencyFormat()}>
                                    {cashflowWidget.lg[i].cost}
                                  </NumericLabel> : '$0.00'
                              }
                            </p>
                          </div>
                        </WidgetCard>
                      )
                      : null
                  }
                  {ctrShortInfo.asset_type == 1 &&
                    cashflowWidget.propeller ?
                    Object.keys(cashflowWidget.propeller).map((prop, index) =>
                      <WidgetCard key={index} md="2" className="padding-right-0 padding-left-xs-0">
                        <WidgetTitle className="para">{cashflowWidget.propeller[prop].name}</WidgetTitle>
                        <div>
                          <h6>Cost</h6>
                          <p>
                            {cashflowWidget.propeller && cashflowWidget.propeller[prop] && parseFloat(cashflowWidget.propeller[prop].cost) ?
                              <NumericLabel params={showCurrencyFormat()}>
                                {cashflowWidget.propeller[prop].cost}
                              </NumericLabel> : '$0.00'
                            }
                          </p>
                        </div>
                      </WidgetCard>
                    ) : null
                  }
                </Row>
              </div>
            </>
            : null
        }
      </>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  cashflowWidget: state.ContrCashFlowReducer.cashflowWidget,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadCashFlowAssetViewAc(ownProps.params)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReserveFundStatus));
