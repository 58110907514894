import React, { Component } from 'react'
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldSelect, FieldInput, FieldTextarea } from '../../Elements';
import {days, } from '../../../../constants';
import { titanInstance ,gaTelesisInstance} from '../../../../shared_elements/components';

export default class EditReserveConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mrRateInvoiceInfo: {},
        }
    }
    componentDidMount() {
        let data = this.props.mrRateInvoiceInfo
        this.setState({
            mrRateInvoiceInfo: data
        });
    }


    updateForm = (type, value) => {
        this.setState(prevState => ({
            ...prevState,
            mrRateInvoiceInfo: {
                ...prevState.mrRateInvoiceInfo,
                [type]: value
            }
        }))
    }

    contrInvcInfoEdit = (e) => {
        e.preventDefault();
        const {mrRateInvoiceInfo}= this.state
        let payload ={
            mr_invoice_day_of_month: mrRateInvoiceInfo.mr_invoice_day_of_month ? mrRateInvoiceInfo.mr_invoice_day_of_month : null,
            mr_invoice_due_day_of_month: mrRateInvoiceInfo.mr_invoice_due_day_of_month ? mrRateInvoiceInfo.mr_invoice_due_day_of_month : null, 
            mr_invoice_is_due_day_next_month: mrRateInvoiceInfo.mr_invoice_is_due_day_next_month,
        }
        if(titanInstance ){
            payload = {...payload, remarks : mrRateInvoiceInfo.remarks_reserve, reference_number :mrRateInvoiceInfo.reference_number_reserve  }
        }else if(gaTelesisInstance){
            payload = {...payload, reference_number :mrRateInvoiceInfo.reference_number_reserve  }
        }
        this.props.contrInvcInfoEdit(payload);
    }

    render() {
        const { mrRateInvoiceInfo } = this.state;
        const { type } = this.props;
        return (
            <form onSubmit={(e) => this.contrInvcInfoEdit(e)}>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
                    <Row>
                        <FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">Invoice Generation Day ( For Every Month )</FieldLabel>
                                <FieldSelect
                                    placeholder='Select Day'
                                    keyParam="mr_invoice_day_of_month"
                                    value={mrRateInvoiceInfo.mr_invoice_day_of_month}
                                    type={type}
                                    options={days}
                                    updateField={this.updateForm}
                                    labelKey='label'
                                    valueKey='value'
                                />
                            </FieldGroup>
                        </FieldCol>
                        <FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">Invoice Due Day ( For Every Month )</FieldLabel>
                                <FieldSelect
                                    placeholder='Select Day'
                                    keyParam="mr_invoice_due_day_of_month"
                                    value={mrRateInvoiceInfo.mr_invoice_due_day_of_month}
                                    type={type}
                                    options={days}
                                    updateField={this.updateForm}
                                    labelKey='label'
                                    valueKey='value'
                                />
                            </FieldGroup>
                        </FieldCol>
                        <Col md="12">
                            <div className="form-group" >
                                <label className="label">
                                    <input type="checkbox" name="mr-invoice" checked={mrRateInvoiceInfo.mr_invoice_is_due_day_next_month} onChange={(e) => this.updateForm('mr_invoice_is_due_day_next_month', e.target.checked)} />
                                    <span> Invoice Due Day falls in next Month</span>
                                </label>
                            </div>
                        </Col>

                        {(titanInstance || gaTelesisInstance) ? <FieldCol md="12">
                            <FieldGroup className="form-group">
                                <FieldLabel className="label">
                                    Reference Number
                                </FieldLabel>
                                <FieldInput
                                    value={mrRateInvoiceInfo.reference_number_reserve ? mrRateInvoiceInfo.reference_number_reserve : ''}
                                    type={type}
                                    keyParam="reference_number_reserve"
                                    updateField={this.updateForm}
                                    maxLength={titanInstance ?50 : 250}
                                />
                                <h6 className="error-msg">{''}</h6>
                            </FieldGroup>
                        </FieldCol> : null}
                        {titanInstance ? <FieldCol md="12">
                            <FieldGroup className="form-group" >
                                <FieldLabel className="label">Remarks</FieldLabel>
                                <FieldTextarea
                                    value={mrRateInvoiceInfo.remarks_reserve ? mrRateInvoiceInfo.remarks_reserve : ""}
                                    type={type}
                                    keyParam="remarks_reserve"
                                    updateField={this.updateForm}
                                    maxLength={500}
                                />
                            </FieldGroup>
                        </FieldCol> : ""}  
                    </Row>
                </div>
                <div className="submit-block">
                    <input type="submit" className="primary-btn" value="Save Changes" />
                    <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
                </div>
            </form>
        )
    }
}
