
import React, { useState } from 'react'
import { TableRow, TableCell, Tooltip, Chip, Typography } from '@material-ui/core';
import moment from 'moment';
import { Delete, Edit } from '@material-ui/icons';
import { checkPermission } from '../../../../utils_v2';
import { gaTelesisInstance } from '../../../../shared_elements/components';

export default function AmendmentList({ item, setEditModal, handleDelete, setDeleteDetailsmodal, previewDoc }) {
    const [showAll, setShowAll] = useState(false);
    const attachmentsToShow = Array.isArray(item?.attachments) ? (showAll ? item.attachments : item.attachments.slice(0, 2)) : [];

    return (
        <TableRow style={{ padding: '4px 0px' }}  >
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.date ? moment(item?.date).format('MMM DD, YYYY') : '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.unique_identifier || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.tsn || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.csn || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }} >
                {item?.description || '--'}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer', width: gaTelesisInstance && attachmentsToShow?.length > 1 ? '200px' : '' }} >
                {attachmentsToShow.length === 0 ? '--' : attachmentsToShow?.length && attachmentsToShow?.map(i =>
                    checkPermission('contracts', 'lease_details', 'U') ?
                        <Tooltip title={`${i.name.substring(0, i.name.lastIndexOf('_'))}.pdf`} arrow placement='top' style={{ marginTop: '3px' }}>
                            <Chip
                                style={{ marginRight: '4px', marginTop: '7px', width: gaTelesisInstance?'70px':'' }}
                                variant="outlined"
                                size="small"
                                onDelete={(e) => { e.preventDefault(); e.stopPropagation(); handleDelete(i) }}
                                label={
                                    <p style={{ fontSize: gaTelesisInstance ? '9px' : '' }}>
                                        {gaTelesisInstance ? i.name.substring(0, 6) + '...' : i.name.substring(0, 14) + '...'}
                                    </p>
                                }
                                clickable
                                color="primary"
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                            />
                        </Tooltip> :
                        <Tooltip title={`${i.name.substring(0, i.name.lastIndexOf('_'))}.pdf`} arrow placement='top' style={{ marginTop: '3px' }}>
                            <Chip
                                style={{ marginRight: '4px', marginTop: '7px', width: gaTelesisInstance?'70px':''  }}
                                variant="outlined"
                                size="small"
                                label={
                                    <p style={{ fontSize: gaTelesisInstance ? '9px' : '' }}>
                                        {gaTelesisInstance ? i.name.substring(0, 6) + '...' : i.name.substring(0, 14) + '...'}
                                    </p>
                                }
                                clickable
                                color="primary"
                                onClick={(e) => { e.preventDefault(); e.stopPropagation(); previewDoc(i) }}
                            />
                        </Tooltip>
                )}
                {item?.attachments?.length > 2 && (
                    <div style={{ marginTop: '10px' }}>
                        <p onClick={() => setShowAll(!showAll)} style={{ cursor: 'pointer', color: 'blue', fontSize:'11px' }}>
                            {showAll ? 'Show Less' : 'Show More'}
                        </p>
                    </div>
                )}
            </TableCell>
            <TableCell className="" style={{ cursor: 'pointer' }}>
                { checkPermission('contracts', 'lease_details', 'U') ?
                    <Edit color='primary' style={{ width: '20px', marginRight: '3px', cursor: "pointer" }} onClick={() => setEditModal(item)} />
                    : null
                }
                { checkPermission('contracts', 'lease_details', 'D') ?
                    <Delete color='secondary' style={{ width: '20px', marginRight: '3px', cursor: "pointer" }} onClick={() => setDeleteDetailsmodal({ flag: true, data: item, type: 'amendment' })} />
                    : null
                }
            </TableCell>
        </TableRow>
    )
}