import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../../../applications/contracts/Elements';
import { ObligationList, ObligationCRU } from '../components';
import { assetsType } from '../../../../constants';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getPlatformConstantsAc } from '../../../../shared_elements/actionCreators';
import { sExportContractsAc } from '../../../actionCreators';
import { getObligationsApi, getApprovedUsersApi ,deleteObligationApi} from '../apiServices';
import { obligationHd } from '../';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
import { getApprovedByUserAc } from '../../../../applications/contracts/Obligations/actionCreators';

class Obligations extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list:[],
      approvedUser:[],
      deleteItem:{modal:false, data:null}
    }
    this.getObligationsApi = getObligationsApi.bind(this);
    this.getApprovedUsersApi = getApprovedUsersApi.bind(this);
    this.deleteObligationApi = deleteObligationApi.bind(this);
  }
  componentDidMount(){
    this.props.fetchApprovedByUsers()
    this.props.fetchContractInfo();
    this.getObligationsApi(this.props);
    this.props.getPlatformConstants();
    trackActivity('Lease Management', { page_title: 'Obligations', event_type: 'Page Visited' })
    setTimeout(()=>{
      const{approvedByUsers}=this.props
      this.getApprovedUsersApi(this.props,{per_page:approvedByUsers?.pagination?.total});
      },2000)
   
  }
  getResponseBack = () => {
    this.getObligationsApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, pageLoader, approvedUser, deleteItem } = this.state;
    const { pltConstants, params, contractSummaryLoader, contractInfo} = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Obligations" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='obligations' primaryTab='basic-betails' secondaryTab='obligations' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctobligations/'
            params={params}
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            buttonText={ checkPermission('contracts','obligations', 'C') ? <ObligationCRU pltConstants={pltConstants} getResponseBack={this.getResponseBack} approvedUser={approvedUser} contractInfo={contractInfo}/> : null}
          >
            { skeletonLoader ?
              (<STableLoader count={7} />):
              (
                <TableListComp
                  heads={obligationHd}
                  data={list.map((item,index) =>
                    <ObligationList
                      pltConstants={pltConstants}
                      approvedUser={approvedUser}
                      getResponseBack={this.getResponseBack}
                      key={index}
                      item={item} 
                      contractInfo={contractInfo}
                      deleteObligation={() => this.setState({deleteItem:{modal:true,data:item}})}
                    />
                     
                    )
                  }
                  noRecord={ list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              )
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteObligationApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  pltConstants: state.shareReducer.pltConstants,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
  approvedByUsers: state.ContrObligationsReducer.approvedByUsers,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['obligation_type', 'obligation_sub_type']})),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    fetchApprovedByUsers : (query) => dispatch(getApprovedByUserAc(ownProps?.params, query)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Obligations,['contracts','obligations', 'R'])));
