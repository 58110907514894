import * as actions from '../actions';
import { globalGetService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel'
import { BASIC_INFO } from '../../Listing/actions';
export const contrHdlineInfoAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.HDLINE_LOADER,
      payload: true
    });
    globalGetService(params.type + '/' +params.aircraft_slug + '/contracts/' + params.contract_slug  + '/',{})
    .then(response => {
      dispatch({
        type: actions.HDLINE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.HD_BASIC_INFO,
          payload: response.data.data
        })
      }
    })
  }
}
export const contrEditHdlineInfoAc = (params, data, type, assetViewId, toggleEditSideBar) => {
  return (dispatch) => {
    dispatch({
      type:actions.HDLINE_LOADER,
      payload:true
    })
    globalPutService(params.type + '/' + params.aircraft_slug + '/contracts/' + params.contract_slug + '/', data)
    .then(response => {
      dispatch({
        type:actions.HDLINE_LOADER,
        payload:false
      })
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        // setTimeout(() => {
        //   window.location.reload();
        // },500);
        let assetTypeNames = { 3: 'APU', 4: 'LG', 5: 'PROPELLER' }
        trackActivity('Item Edited', {
          page_title: `Headlines`,
          application_module: `Contracts`,
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
          contract_slug:`${params.contract_slug}`,
          item_type: type=='Lessor'?`Lessor`: type=='Lessee' ? `Lessee` : `Lease`
        })
      }
    });
  }
}
export const portfolioAc = () => {
  return (dispatch) => {
    globalGetService('console/lessor-portfolios/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.PORTFOLIO_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
