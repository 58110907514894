import * as actions from './actions';
import { globalGetService, globalExportService } from '../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType } from '../utils_v2';
export const getContractClauseAc = (props) => {
  return (dispatch) => {
    globalGetService(`contracts/${props.params.contract_slug}/contract-asset-conditions-list/`, {})
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.CLAUSE_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const getReserveAccountsAc = (props) => {
  return (dispatch) => {
    globalGetService(`contracts/${props.params.contract_slug}/reserve-accounts/`, {})
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.RESERVE_ACCOUNTS,
          payload: response.data.data
        })
      }
    })
  }
}

export const sExportContractsAc = (basicInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_EXPORT_LOADER,
      payload: true
    });
    globalExportService('/contracts/'+basicInfo.slug+'/export-contract-summary/',{download: 'pdf'})
    .then(response => {
      dispatch({
        type: actions.CONTRACT_EXPORT_LOADER,
        payload: false
      });

      if(basicInfo.asset_type == 2){
        downloadFileType(response.data, 'ESN'+(basicInfo.asset.asset_name ? basicInfo.asset.asset_name:''+'_'+basicInfo.lessee.name)+'_Lease', 'pdf')
      }else if(basicInfo.asset_type == 3){
        downloadFileType(response.data, 'APU'+(basicInfo.asset.serial_number ? basicInfo.asset.serial_number:''+'_'+basicInfo.lessee.name)+'_Lease', 'pdf')
      }else if(basicInfo.asset_type == 4){
        downloadFileType(response.data, 'LG'+(basicInfo.asset.serial_number ? basicInfo.asset.serial_number:''+'_'+basicInfo.lessee.name)+'_Lease', 'pdf')
      }else if(basicInfo.asset_type == 5){
        downloadFileType(response.data, 'PROPELLER'+(basicInfo.asset.serial_number ? basicInfo.asset.serial_number:''+'_'+basicInfo.lessee.name)+'_Lease', 'pdf')
      }else if (basicInfo.asset_type == 6) {
        downloadFileType(response.data, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease', 'pdf')
      }else if (basicInfo.asset_type == 7) {
        downloadFileType(response.data, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease', 'pdf')
      }else if (basicInfo.asset_type == 8) {
        downloadFileType(response.data, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease', 'pdf')
      }else if (basicInfo.asset_type == 9) {
        downloadFileType(response.data, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease', 'pdf')
      }else if (basicInfo.asset_type == 1) {
        downloadFileType(response.data, 'MSN' + basicInfo.lessee.name + '_Lease', 'pdf')
      }
    });
  }
}

export const returnCondListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.RETURN_COND_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/return-condition-items/',{})
    .then(response => {
      dispatch({
        type: actions.RETURN_COND_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.RETURN_COND_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const contrDbFlaggedCondAc = (contract_slug, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_WIDGET_LOADER,
      loaderType: 'flaggedWidgetLoader',
      payload: true
    });
    globalGetService('/contracts/'+contract_slug+'/return-vs-delivery-condition/', queryParams)
    .then(response => {
      if(response.data.statusCode == 200){
        if(Object.keys(queryParams).length){
          dispatch({
            type: actions.CONTR_DB_FLAGGED_COND,
            payload: response.data.data
          });
        }else{
          dispatch({
            type: actions.COMPARE_RETURN_COND,
            payload: response.data.data
          })
        }
      }
      dispatch({
        type: actions.CONTRACT_WIDGET_LOADER,
        loaderType: 'flaggedWidgetLoader',
        payload: false
      });
    })
  }
}

export const exportReturnCondnAc = (contract) => {
  return (dispatch) => {
    globalExportService('contracts/'+contract.contract.slug+'/return-vs-delivery-condition/',{download: 'pdf'})
    .then(response => {
      if (contract.asset_type === 2){
        downloadFileType(response.data, `ESN${contract.esn}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if(contract.asset_type === 3){
        downloadFileType(response.data, `APU${contract.serial_number}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if(contract.asset_type === 4){
        downloadFileType(response.data,`LG${contract.serial_number}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if(contract.asset_type === 5){
        downloadFileType(response.data, `Propeller${contract.serial_number}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if(contract.asset_type === 1){
        downloadFileType(response.data, `MSN${contract.msn}_${contract.contract.lessee.name}_return_condition`,'pdf')
      }else if (contract.asset_type === 6) {
        downloadFileType(response.data, `FAN_${contract.esn}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if (contract.asset_type === 7) {
        downloadFileType(response.data, `HPC_${contract.esn}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if (contract.asset_type === 8) {
        downloadFileType(response.data, `HPT_${contract.esn}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }else if (contract.asset_type === 9) {
        downloadFileType(response.data, `LPT_${contract.esn}_${contract.contract.lessee.name}_return_condition`, 'pdf')
      }
    });
  }
}

export const getLesseeListAc = (props={}, query={}) => {
  return (dispatch) => {
    globalGetService(`console/lessees/`, query)
    .then(response => {
      if(checkApiStatus(response)){
        dispatch({
          type: actions.LESSEE_LIST,
          payload: response.data.data.lessees
        })
      }
    })
  }
}
