import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import { Link, browserHistory } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import 'react-select/dist/react-select.css';
import { Modal as MuiModal, Button as MuiButton, Chip } from '@material-ui/core'
import { ContractSideBar, Header } from '../../Elements';
import UtilsFields from '../components/UtilsFields';
import { UtilsAssetViewAc, addEditUtilizationAc, exportSampleUtilizationAc, utilsInitiateAc } from '../actionCreators';
import { checkString } from '../../../../formValidator';
import { globalPostService } from '../../../../globalServices'
import { UPDATE_UTIL_FIELD, IMPORT_UTILS, UTILS_LOADER } from '../actions';
import { ListLoader } from '../../../../shared';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import GenerateInvoice from '../../../../lease_management/utilizations/components/GenerateInvoiceModal';
import { checkPermission, getLocalStorageInfo } from '../../../../utils_v2';
import LLpSplit from '../components/LLpSplit';
import { sExportContractsAc } from '../../../../shared/actionCreators';
const months = [
  { label: 'Jan', value: '01' },
  { label: 'Feb', value: '02' },
  { label: 'Mar', value: '03' },
  { label: 'Apr', value: '04' },
  { label: 'May', value: '05' },
  { label: 'Jun', value: '06' },
  { label: 'Jul', value: '07' },
  { label: 'Aug', value: '08' },
  { label: 'Sep', value: '09' },
  { label: 'Oct', value: '10' },
  { label: 'Nov', value: '11' },
  { label: 'Dec', value: '12' }
];
const util_ratio = 1.1;
const decimalRegex = /^[0-9\.\/]+$/;
class AddEditUtils extends Component {
  constructor(props) {
    super(props);
    this.state = {
      months: [],
      year: '',
      month: '',
      error: {},
      uploadError: '',
      nameError: '',
      importAssets: [],
      attatchedDoc: [],
      attachedDocList:[],
      addAttachmentModal: false,
      warningModal:{},
      modal:{},
      isSecondButtonClicked: false,
      lastPhase: false,
      lLpSplit:false,
      llpSplitData:{}
    }
  }

  componentDidMount() {
    this.props.fetchUtilsInfo('add');
    this.props.utilsInitiateFn();
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
        [keyParam]:value
    }))
  }
  importUtilization = (files) => {
    this.setState({ uploadError: '' })
    if (!files[0].name.toLowerCase().includes('.xls') && !files[0].name.toLowerCase().includes('.clv') && !files[0].name.toLowerCase().includes('.docx') && !files[0].name.toLowerCase().includes('.xlsx') && files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && files[0].type !== 'application/vnd.ms-excel') {
      this.setState({ uploadError: 'Only xls, xlsx, docx and clv are allowed to upload.' })
      return
    }
    this.addUtilsAttachment(this.props, files)
    this.setState({addAttachmentModal:false})
  }

  yearMonth = (value, type) => {
    if (type == 'year') {
      if (value) {
        if(this.props.utilsInitiate.initial_utilization && this.props.utilsInitiate.lease_duration_type != 1){
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: months.filter( i => parseInt(i.value) == parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')))
          }));
        }
        else{
        if (value == parseInt(moment().format('YYYY')) && moment().format('YYYY') == moment(this.props.utilsInitiate.utilization.start_month).format('YYYY')) {
          // Current Year == Contract year
          let currentDate = false;
          let mt = [];
          if (moment(this.props.utilsInitiate.utilization.start_month).format('YYYYMM') == moment().format('YYYYMM')) {
            if (this.props.utilsInitiate.utilization.is_contract_start_date) {
              currentDate = true;
            }
          }
          if (this.props.utilsInitiate.utilization.is_contract_start_date) {
            currentDate = true;
          }
          if (currentDate) {
            mt = months.filter((month, index) => parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')) <= (index + 1) && (index + 1) <= parseInt(moment().format('M')));
          } else {
            mt = months.filter((month, index) => parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')) < (index + 1) && (index + 1) <= parseInt(moment().format('M')));
          }
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: mt
          }));
        } else if (value == parseInt(moment().format('YYYY'))) {
          // Checking Current Year
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: months.filter((month, index) => (index + 1) <= parseInt(moment().format('M')))
          }));
        } else if (value == moment(this.props.utilsInitiate.utilization.start_month).format('YYYY')) {
          // Checking Year as Contract year
          let sameMonth = false, monthlist = [];
          if (this.props.utilsInitiate.utilization.is_contract_start_date) {
            sameMonth = true;
          }
          if (sameMonth) {
            monthlist = months.filter((month, index) => (index + 1) >= parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')))
          } else {
            monthlist = months.filter((month, index) => (index + 1) > parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')))
          }
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: monthlist
          }));
        } else {
          // Any Other Year
          this.setState(prevState => ({
            ...prevState,
            year: value,
            months: months
          }));
        }
      }
      } else {
        this.setState(prevState => ({
          ...prevState,
          year: value,
          months: [],
          month: ''
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        month: value
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }

  importUtilizationFile = (files) => {
    let data = new FormData()
    data.append('file', ...files)
    data.append('year', this.state.year)
    data.append('month', this.state.month)
    this.props.dispatchActions({
      type: UTILS_LOADER,
      payload: true
    });
    globalPostService(`contracts/${this.props.params.type}/${this.props.params.aircraft_slug}/utilizations/import/`, data)
      .then(response => {
        if (response.data.statusCode == 200) {
          if (response.data.data.list.filter(item => item.errors && item.errors.name && item.errors.name.trim() !== '').length) {
            this.setState({ nameError: true, importAssets: response.data.data.list.filter(item => item.errors && item.errors.name && item.errors.name.trim() !== '') })
          } else {
            if (response.data.data.list && response.data.data.list.length) {
              this.props.dispatchActions({
                type: IMPORT_UTILS,
                payload: response.data.data.list
              })
            }
            // setTimeout(() => this.addEditUtils())
          }
        }
        this.props.dispatchActions({
          type: UTILS_LOADER,
          payload: false
        });
      })
  }

  addEditUtils = (e,isSecondButton,extraProps,generateInvoice) => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }
    let data = {};
    let utilsCond = [], tsnVal = [], hoursVal = [], cyclesVal = [], csnVal = [], tsnMin = [], csnMin = [], cyclesMinVal = [];
    data = {
      ...data,
      year: this.state.year,
      month: this.state.month,
      assets: this.props.utilsInitiate.assets,
    }
    this.setState({ isSecondButtonClicked: isSecondButton });
    const validateNewInput = {
      year: checkString({ value: data.year, required: true, minLength: '', maxLength: '', message: 'Please Select The Year' }),
      month: checkString({ value: data.month, required: true, minLength: '', maxLength: '', message: 'Please Select The Month' }),
    };

    this.props.utilsInitiate.assets.map(assetUitl => {
      if (assetUitl.tsn_as_of_last_month >= 0 && (assetUitl.tsn === undefined || assetUitl.tsn < 0)) {
        // tsnVal.push(false);
      } else {
        tsnVal.push(true);
      }
      if (assetUitl.tsn < assetUitl.tsn_as_of_last_month) {
        tsnMin.push(false)
      } else {
        tsnMin.push(true)
      }
      if (assetUitl.csn < assetUitl.csn_as_of_last_month) {
        csnMin.push(false)
      } else {
        csnMin.push(true)
      }
   
      if (assetUitl.hours >= 0) {
        // if(assetUitl.name != 'APU' && parseFloat(assetUitl.hours % Math.floor(assetUitl.hours)) > 0.60 ){
        //   hoursVal.push(false);
        // }else{
        //   hoursVal.push(true);
        // }
        hoursVal.push(true);
      } else {
        hoursVal.push(false);
      }

      if (assetUitl.cycles >= 0) {
        cyclesVal.push(true);
      } else {
        cyclesVal.push(false);
      }


      if ((parseInt(assetUitl.csn_as_of_last_month) >= 0 && (parseInt(assetUitl.csn) === undefined || parseInt(assetUitl.csn) <= 0)) && (assetUitl.name != "APU")) {
        // csnVal.push(false);
      } else {
        csnVal.push(true);
      }


      if (this.checkUtilsCond(assetUitl.tsn) && this.checkUtilsCond(assetUitl.csn)) {

        utilsCond.push(true);

      } else if (this.checkUtilsCond(assetUitl.hours) && this.checkUtilsCond(assetUitl.cycles)) {
        if (assetUitl.remarks) {
          utilsCond.push(true);
        } else {
          utilsCond.push(false);
        }
      } else {
        if ((assetUitl.remarks === '' || assetUitl.remarks === undefined || assetUitl.remarks === null) && (assetUitl.hours < assetUitl.avg_hours || assetUitl.cycles < assetUitl.avg_cycles)) {
          utilsCond.push(false);
        } else {
          utilsCond.push(true);
        }
      }
    });
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' }) && (utilsCond.indexOf(false) == -1) && (hoursVal.indexOf(false) == -1) && (csnVal.indexOf(false) == -1) && (tsnVal.indexOf(false) == -1) && (cyclesVal.indexOf(false) == -1)) {
      if (this.props.utilsInitiate.assets.filter(item => [1, 2].includes(item.asset_type) && item.cycles == 0)?.length) {
        if (generateInvoice) {
          this.props.addEditUtilization(this.props.params.contract_slug, data, this.props.assetViewId, false, generateInvoice)
        } else {
          this.setState({ warningModal: { data: data, flag: true, type: 'red' } })
        }
      }
      else if (this.props.utilsInitiate.assets.filter(item => [1, 2].includes(item.asset_type) && item.cycles > 0 && item.cycles < 10)?.length) {
        if (generateInvoice) {
          this.props.addEditUtilization(this.props.params.contract_slug, data, this.props.assetViewId, false, generateInvoice)
        } else {
          this.setState({ warningModal: { data: data, flag: true, type: 'yellow' } })
        }
      }
      else {
        if (isSecondButton) {
          this.setState({ modal: { data: data, test: true } });
        } else if (extraProps) {
          this.props.addEditUtilization(this.props.params.contract_slug, data, this.props.assetViewId, false, generateInvoice)
        } else {
          this.props.addEditUtilization(this.props.params.contract_slug, data, this.props.assetViewId, true)

        }
      }
    } else {
      this.setState({
        error: {
          ...validateNewInput,
          utilsErr: utilsCond,
          tsnErr: tsnVal,
          tsnMinErr: tsnMin,
          hoursErr: hoursVal,
          cyclesErr: cyclesVal,
          csnErr: csnVal,
          csnMinErr: csnMin,
          cyclesMinErr: cyclesMinVal
        }
      });
    }
  }
  checkUtilsCond = (value) => {
    return (value == '' || value == undefined || value == null || value == 0);
  }
  handleclose = () => {
    this.setState({ addAttachmentModal: false })
  }
  handleDelete =(index)=>{
    delete this.state.attatchedDoc[index]
    this.setState({attatchedDoc:this.state.attatchedDoc})
  }

  updateUtilField = (index, value, keyParam, totalHours,totalCycles) => {
    if (keyParam == 'remarks') {
      this.setState({
        value: value,
      });
    } else {
      let newValue = parseFloat(value);
      this.setState({
        error: { ...this.state.error, [keyParam + 'Err']: { ...this.state.error[keyParam + 'Err'], [index]: true } }
      })
      // if(index != 3){
      //   let decimal = value % 1
      //   if(parseInt(decimal.toFixed(2).substring(2)) == 60){
      //     value = Math.floor(value) + 1
      //     // if((parseInt(decimal.toFixed(2).substring(2) % 60) / 10) < 10){
      //     //   value = parseFloat(value + (parseInt(decimal.toFixed(2).substring(2) % 60) / 100)).toFixed(2)
      //     // }else{
      //     //   value = parseFloat(value.toString() + '.' + (parseInt(decimal.toFixed(2).substring(2) % 60))).toFixed(2)
      //     // }
      //   }
      //   newValue = parseFloat(value);
      // }
      // if( index != 3 && parseFloat(newValue % Math.floor(newValue)) > 0.60 ){
      //   var keyError = keyParam + 'Err';
      //   this.setState({
      //     error: {
      //       ...this.state.error,
      //       [keyError]: {
      //         ...this.state.error[keyError],
      //         [index]: false
      //       }
      //     }
      //   });
      // }
    }
    this.props.updateUtilField(index, value, keyParam, totalHours, totalCycles);
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts/';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  closeModal = () => {
    this.setState({ modal: { test: false } });
  };
  render() {
    
    const { utilsInitiate, utilsInfo, utilsLoader } = this.props;
    const { addAttachmentModal, attatchedDoc, warningModal,modal, lLpSplit,llpSplitData } = this.state
    const years = [], months = [];
    const currentYear = parseInt(moment().format('YYYY'));
    let contractStart = parseInt(moment(utilsInitiate.utilization.start_month).format('YYYY'));
    let contractEnd = parseInt(moment(utilsInitiate.utilization.end_month).format('YYYY'));
    if (moment(utilsInitiate.utilization.start_month).format('M') == '12' && !utilsInitiate.utilization.is_contract_start_date) {
      contractStart++;
    }
    if(utilsInitiate.initial_utilization && utilsInitiate.lease_duration_type != 1){
      years.push({ label: contractStart, value: contractStart })
    }
    else{
      while (contractStart <= contractEnd) {
        years.push({ label: contractStart, value: contractStart })
        contractStart += 1
      }
    }
    let oldFlow= !getLocalStorageInfo()?.defaultLessor?.llp_multi_thrust;
    return (
      <div className="contract-container cash-flow-wrapper">
        <ContractSideBar application={`Edit Utilization`} type="brief" params={this.props.params} />
        <Header type="brief" headingText="Lease Management" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <div>
          {!modal.test? utilsLoader || this?.props?.contractSummaryLoader ? <ListLoader /> : null:null}
          <div className="add-edit-utils" style={{ marginLeft: '45px' }}>
            <h5>
              <Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/contract/${this.props.params.contract_slug}/utilization`}>
                <img src={imgStoragePath + "back_arw.png"} alt="img" width="16" />
                Add Monthly Utilization
              </Link>
              <div className="flex-centered util-info-block">
                <div className="info-img-block">
                  <img src={imgStoragePath + "icon_info_orange.png"} alt="img" />
                  <span>INFO</span>
                </div>
                <ul className="bullet-guid " style={{ listStyle: "inherit" }}>
                  <li style={{ position: "relative", top: "5px", color: 'black' }}>
                    <span className="note-yellow">Present location of original assemblies pertains to the airframe on which it is currently installed. Ensure to review and upate as applicable.
                    </span>
                  </li>
                    <li style={{ position: "relative", top: "5px", color: 'black' }}>
                      <span className="note-yellow"> In case of low or NIL utilization, please ensure to add appropriate remarks for the assembly.   </span>
                    </li>
                  <li style={{ position: "relative", top: "5px", color: 'black' }}>
                    <span className="note-yellow">Enter utilisation in Decimals only. For calculation: For example 1 Hour 31 min = 1 + (31/60) = 1.5166 ~1.52.
                    </span>
                  </li>
                    {/* <span className="warning-info-text">Enter utilisation in Decimals only. For calculation: For example 1 Hour 31 min = 1 + (31/60) = 1.5166 ~1.52.</span> */}
                  <li style={window.location.href.includes('propeller') ? { display: 'none' } : { position: "relative", top: "5px", display: 'none' }}>
                    <span className="note-yellow">You can import utilization or can enter manually.
                    </span>
                  </li>
                </ul>
              </div>
            </h5>
            <ul className="list-inline flex-centered select-util-block">
              <li className="list-inline-item">
                <label className="label">Select Year <sup className="reqStar"> * </sup></label>
                <Select
                  name="form-field-name"
                  placeholder="Select Year"
                  className="custom-select-block"
                  value={this.state.year}
                  options={years}
                  onChange={(target) => this.yearMonth(target ? target.value : '', 'year')}
                />
                <h6 className="error-msg">{this.state.error?.year}</h6>
              </li>
              <li className="list-inline-item">
                <label className="label">Select Month <sup className="reqStar"> * </sup></label>
                <Select
                  name="form-field-name"
                  placeholder="Select Month"
                  className="custom-select-block"
                  value={this.state.month}
                  options={this.state.months}
                  onChange={(target) => this.yearMonth(target ? target.value : '', 'month')}
                />
                <h6 className="error-msg">{this.state.error?.month}</h6>
              </li>

              {
                // this.state.month !== null && this.state.month !== '' ?
                // <a style={['propeller','hpt','fan','lpt','hpc'].includes(this.props.params.type) ? {display: 'none'} : {marginLeft: '10px', fontSize: '13px', color: '#2e8cff', marginTop: '10px'}} onClick={() => this.props.exportSampleUtilization(assetViewId)}>Download Sample Report</a>
                // :null
              }
              {
                attatchedDoc ?
                  <li>{
                    attatchedDoc.map((i,index) => <div><Chip className="chip-style" label={i.name} onDelete={()=>this.handleDelete(index)} >
                    <img src={imgStoragePath + "back_arw.png"} alt="img" width="16" onClick={(item, index)=> {console.log('')}}/>
                    </Chip> 
                    </div>)
                  }</li>
                  : null
              }
              {
                // this.state.month !== null && this.state.month !== '' ?

                //   <li>
                //     <div style={['propeller', 'hpt', 'fan', 'lpt', 'hpc'].includes(this.props.params.type) ? { display: 'none' } : {}}>
                //       <div className="mluploaded-file data-log-list" style={{ marginLeft: '0px' }}>
                //         <DropToUpload
                //           onDrop={(files) => this.importUtilization(files)}
                //         >
                //           <div className="drag-drop-block" style={{ background: 'transparent', marginBottom: '0px', padding: '10px' }}>
                //             <div>
                //               <p className="caption-ui">Drag and drop file here or </p>
                //               <p style={{ marginTop: '20px' }}>
                //                 <Link className="primary-btn"> Add Attachment
                //                   <input type="file" accept='.doc,.docx,.xml,application/msword, .xls, .jpg, .png' onChange={(e) => { this.importUtilizationFile(e.target.files); this.setState({ uploadError: '' }) }} multiple />
                //                   {/* <input type="file" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel' onChange={(e) => {this.importUtilizationFile(e.target.files);this.setState({uploadError: ''})}} multiple/> */}
                //                 </Link>
                //               </p>
                //             </div>
                //             <h6 className="error-msg">{this.state.uploadError}</h6>
                //           </div>
                //         </DropToUpload>
                //       </div>
                //     </div>
                //   </li>
                // : null

              }
            </ul>
            <form className="table-block" >
              <table style={{ background: '#fff' }}>
                <thead>
                  <tr>
                    <th>Major Assembly</th>
                    <th>TSN as of Last Month</th>
                    <th>CSN as of Last Month</th>
                    <th>Hours Utilized in Selected Month</th>
                    <th>Cycles Utilized in Selected Month</th>
                    <th>TSN as of Selected Month</th>
                    <th>CSN as of Selected Month</th>
                    <th>Present Location of Original Assembly</th>
                    <th>Remarks</th>
                    <th>Serviceability</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    utilsInitiate.assets.map((asset, index) =>
                      <tr key={index}>
                        <td>{asset.name}</td>
                        <td>
                          {asset.initial_utilization ?
                            <UtilsFields
                              index={index}
                              value={asset.tsn_as_of_last_month}
                              keyParam="tsn_as_of_last_month"
                              updateUtilField={this.updateUtilField}
                              width="90px"
                            />
                            :
                            asset.tsn_as_of_last_month > 0 ?
                              asset.tsn_as_of_last_month : 0
                          }

                        </td>
                        <td>
                          {asset.initial_utilization ?
                            <UtilsFields
                              index={index}
                              value={asset.csn_as_of_last_month}
                              keyParam="csn_as_of_last_month"
                              updateUtilField={this.updateUtilField}
                              width="90px"
                            />
                            :
                            asset.csn_as_of_last_month > 0 ?
                              asset.csn_as_of_last_month : 0
                          }
                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.hours}
                            keyParam="hours"
                            updateUtilField={this.updateUtilField}
                            width="90px"
                            disabled={asset?.asset_type == 2 && !oldFlow }
                          />
                          {
                            asset?.asset_type == 2 && !oldFlow ? <div className='lnk-redrxn' onClick={()=>this.setState({lLpSplit:{modal:true,data:asset,viewType:'add', index:index,keyname:'hours_llp_split'}})}>Add Thrust Split</div>: null
                          }
                          <h6 className="error-msg">{this.state.error?.hoursErr && this.state.error?.hoursErr[index] == false ? 'Enter Valid Hours' : ''}</h6>
                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.cycles}
                            keyParam="cycles"
                            updateUtilField={this.updateUtilField}
                            width="90px"
                            disabled={asset?.asset_type == 2 && !oldFlow }
                          />
                          {
                            asset?.asset_type == 2 && !oldFlow ? <div className='lnk-redrxn' onClick={()=>this.setState({lLpSplit:{modal:true,data:asset,index:index,viewType:'add', keyname:'csn_llp_split'}})}>Add Thrust Split</div>: null
                          }
                          <h6 className="error-msg">{this.state.error?.cyclesErr && this.state.error?.cyclesErr[index] == false ? 'Enter Valid Cycles' : ''}</h6>
                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.tsn}
                            keyParam="tsn"
                            updateUtilField={this.updateUtilField}
                            width="90px"
                          />
                          <h6 className="error-msg">{this.state.error?.tsnErr && this.state.error?.tsnErr[index] == false ? 'Enter valid TSN' : ''}</h6>
                          <h6 className="error-msg">{this.state.error?.tsnMinErr && this.state.error?.tsnMinErr[index] == false ? 'Can not be lesser than previous TSN' : ''}</h6>

                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.csn}
                            keyParam="csn"
                            updateUtilField={this.updateUtilField}
                            width="90px"
                          />
                          <h6 className="error-msg">{this.state.error?.csnErr && this.state.error?.csnErr[index] == false ? 'Enter valid CSN' : ''}</h6>
                          <h6 className="error-msg">{this.state.error?.csnMinErr && this.state.error?.csnMinErr[index] == false ? 'Can not be lesser than previous CSN' : ''}</h6>
                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.asset_location}
                            keyParam="asset_location"
                            updateUtilField={this.updateUtilField}
                            type="textArea"
                            width="90px"

                          />
                        <h6 className="error-msg"></h6>
                        <h6 className="error-msg"></h6>
                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.remarks}
                            keyParam="remarks"
                            updateUtilField={this.updateUtilField}
                            type="textArea"
                            width="90px"

                          />
                           <h6 className="error-msg"></h6>
                          <h6 className="error-msg">{this.state.error?.utilsErr && this.state.error?.utilsErr[index] == false ? 'Remarks is required since the current month utilization is less than 15% of previous month ' : ''}</h6>
                        </td>
                        <td>
                          <UtilsFields
                            index={index}
                            value={asset.serviceability}
                            keyParam="serviceability"
                            updateUtilField={this.updateUtilField}
                            maxLength='25'
                            type="textArea"
                            width="90px"
                          />
                           <h6 className="error-msg"></h6>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
              <input className="btn btn-primary addutil" disabled={utilsLoader} type="button" value={utilsLoader ? 'Processing..' : 'SUBMIT'}  onClick={(e) => this.addEditUtils(e, false)} />
             { checkPermission('contracts','invoices','C') ?
              <input onClick={(e) => this.addEditUtils(e, true)} style={{ marginLeft: '5px' }} className="btn btn-primary addutil" disabled={utilsLoader} type="submit"  value={utilsLoader ? 'Processing..' : 'SUBMIT & GENERATE RESERVE INVOICE'} />:null}
          </form>
          </div>
        </div>
     {modal.test===true?
      <GenerateInvoice
        isOpen={modal.test}
        slug={this.props.params.contract_slug} 
        month={this.state.month}
        year={this.state.year}
        handleClose={this.closeModal}
        params={this.props.params}
        submit={this.addEditUtils}
        utilsLoader={utilsLoader}
        okpress={this.state.isSecondButtonClicked}
      />:null}
        <Modal isOpen={this.state.nameError} className="modal-container" onClick={() => this.setState({ nameError: false })}>
          <ModalHeader onClick={() => this.setState({ nameError: false })} >Import Error</ModalHeader>
          <ModalBody onClick={() => this.setState({ nameError: false })}>
            <h6 style={{ fontSize: '16px' }}>Following critical errors found in your sheet.</h6>
            <ul className="features-list" style={{ listStyle: 'disc' }}>
              {
                this.state.importAssets.map(item =>
                  <li>
                    <span style={{ fontSize: '12px' }}>{item.name}</span>
                    <h6 className="error-msg">{item.errors.name}</h6>
                  </li>
                )
              }
            </ul>
          </ModalBody>
          <ModalFooter onClick={() => this.setState({ nameError: false })}>
            <Button color="primary" onClick={() => this.setState({ nameError: false })}>OK</Button>
          </ModalFooter>
        </Modal>
        {
          lLpSplit?.modal ?
            <LLpSplit
            lLpSplit={lLpSplit}
              data={lLpSplit?.data}
              onClose={() => this.setState({ lLpSplit: false })}
              saveLLpSplit={(data,totalHours, totalCycles) => {this.setState({ lLpSplit:{...lLpSplit, modal:false} });this.updateUtilField(lLpSplit?.index,data, 'thrust_split', totalHours, totalCycles)}}
            />
            : null
        }
        {
          warningModal.flag ?
            <Modal isOpen={true} className="modal-container-low-utils" style={{ verticalAlign: 'top', marginTop: '50px' }} >
              <ModalHeader style={warningModal?.type == 'red' ? { background: '#ff7474' } : { background: 'yellow' }} > <h6 style={{ fontSize: '16px' }}>Low Utilization added</h6></ModalHeader>
              <ModalBody >

                <p> Dear User,</p>

                Utilization updated for the <h6 ><b> {this.props.assetViewId.asset_type == 1 ? `MSN ${this.props.assetViewId.msn}` : `ESN ${this.props.assetViewId.esn}`} </b></h6> is {warningModal?.type == 'red' ? '0' : 'low'} for <h6> {moment(this.state.month).format('MMM')}-{this.state.year}. </h6>

                <p> Request you to kindly check with the operator if the asset is grounded or planned for SV.</p>

              </ModalBody>
              <ModalFooter >
                <MuiButton
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                    if (this.state.isSecondButtonClicked) {
                      this.setState({ warningModal: { data: {}, flag: false, type: this.state.warningModal.type } });
                      this.setState({ modal: { data: this.state.warningModal.data, test: true } });
                    } else {
                      this.setState({ warningModal: { data: {}, flag: false, type: this.state.warningModal.type } });
                      this.props.addEditUtilization(this.props.params.contract_slug, this.state.warningModal.data, this.props.assetViewId, true);
                    }
                  }}
                >
                  OK
                </MuiButton>
              </ModalFooter>
            </Modal>
            : null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader:state.sharedReducers.contractSummaryLoader,
  utilsInfo: state.ContractListReducer.ctrShortInfo,
  utilsInitiate: state.UtilsReducer.utilsInitiate,
  utilsLoader: state.UtilsReducer.utilsLoader,
  assetViewId: state.userAssetReducer.assetViewId,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchUtilsInfo: (type) => dispatch(UtilsAssetViewAc(ownProps.params, type)),
    addEditUtilization: (contractSlug, data, assetViewId,shouldRedirect,generateInvoice) => dispatch(addEditUtilizationAc(ownProps.params, contractSlug, data, assetViewId, shouldRedirect,generateInvoice)),
    utilsInitiateFn: () => dispatch(utilsInitiateAc(ownProps)),
    dispatchActions: (actionData) => dispatch(actionData),
    exportSampleUtilization: (asset) => dispatch(exportSampleUtilizationAc(ownProps.params, asset)),
    updateUtilField: (index, value, keyParam,totalHours, totalCycles) => dispatch({
      type: UPDATE_UTIL_FIELD,
      payload: { index, value, keyParam,totalHours, totalCycles }
    }),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(AddEditUtils, ['contracts', 'utilization', 'C']));
